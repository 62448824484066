import Footer from '@components/layout/footer/Footer';
import HeaderWrapper from '@components/layout/header/headerWrapper';

import { Outlet, useLoaderData } from 'react-router-dom';

import { CurriculumParams } from '@store/curriculum/CurriculumParams';
import './curriculum.scss';
import LeerplanHeader from './section/header/LeerplanHeader';
import CurriculumTabs from './tabs/CurriculumTabs';

const Curriculum: React.FC = () => {
  const data = useLoaderData() as { params?: CurriculumParams };

  return (
    <>
      <div id="fixed-header" className="fixed-header">
        <HeaderWrapper></HeaderWrapper>
      </div>

      {'preview' in data && data.preview && <div className="preview-example">VOORBEELD</div>}

      <LeerplanHeader />

      <CurriculumTabs />

      <div className="row nomargin">
        <Outlet context={{ params: data.params }} />
      </div>

      <Footer />
    </>
  );
};

export default Curriculum;
