import { useEffect, useRef, useState } from 'react';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import ModalErrorBoundary from '@UI/errorBoundaries/ModalErrorBoundary';
import { isIframe, isValidPopupDate } from '@utils/utils';

import addDays from 'date-fns/addDays';
import settings from '@config/settings';

// Utility function to get a cookie by name
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

// Utility function to set a cookie
const setCookie = (name, value, expires) => {
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
};

const PopupModal = () => {
  const [popup, setPopup] = useState(null);
  const [title, setTitle] = useState('');
  const [html, setHtml] = useState('');
  const modalRef = useRef();

  useEffect(() => {
    if (settings.popup && settings.popup.length > 0) {
      const [firstPopup] = settings.popup;
      setPopup(firstPopup);
      setTitle(firstPopup.title);
      setHtml(firstPopup.body);
      const cookie = getCookie(`popup-${firstPopup.key}`);

      if (!cookie && isValidPopupDate(firstPopup) && !isIframe) {
        modalRef.current?.open({});
      }
    }
  }, []);

  const onHandleClose = () => {
    const expires = addDays(new Date(), popup.ttl || 1);
    setCookie(`popup-${popup.key}`, true, expires);

    modalRef.current?.close();
  };

  return (
    <>
      <Modal
        ref={modalRef}
        size="md"
        component={
          <div className={`popup-main ${popup?.fullSize ? 'full-size' : ''}`}>
            <Modal.Header title={title} onClose={onHandleClose} />
            <Modal.Body>
              <div className="modal-body">
                <div className="resource custom-html">
                  <div dangerouslySetInnerHTML={{ __html: html }}></div>
                </div>
              </div>
            </Modal.Body>
          </div>
        }
        errorBoundaryComponent={ModalErrorBoundary}
      />
    </>
  );
};

export default PopupModal;
