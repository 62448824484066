import { activityPlansApi } from '@store/apihelpers';
import { FileBatch } from '@store/llinkidApis/llinkidApiTypes';
import settings from '@config/settings';
import { BatchRequest } from '../../types/sriTypes';

export function getNewDelay(currentDelay: number, encounteredError: boolean) {
  if (!encounteredError) {
    return settings.asyncSaving.minDelay;
  }
  const delay = Math.min(currentDelay * 2, settings.asyncSaving.maxDelay);
  console.log('Because of error, Next delay: ', delay);
  return delay;
}

export function isBatchUseful(batch: BatchRequest, nextBatch: BatchRequest | undefined) {
  if (
    batch &&
    nextBatch &&
    batch.length === nextBatch.length &&
    batch.every((i) => i.verb === 'PUT') &&
    nextBatch.every((i) => i.verb === 'PUT')
  ) {
    const batchHrefs = new Set(batch.map((e) => e.href));
    if (nextBatch.every((i) => batchHrefs.has(i.href))) {
      // this batch is completely overwritten by the next batch.
      return false;
    }
  }
  return true;
}

export async function handleBatchItem(batch: BatchRequest) {
  const result = await activityPlansApi.post('/llinkid/activityplanning/batch', batch, {
    keepBatchAlive: false,
  });
  if (result === false) {
    throw new Error('batch post failed');
  }
  return result;
}

export async function handleFileBatchItem(fileBatch: FileBatch) {
  const data = new FormData();
  data.append('body', JSON.stringify(fileBatch.body));
  for (const file of fileBatch.files) {
    data.append('data', file, file.name);
  }
  const response = await fetch(
    `${settings.apisAndUrls.activityplansapi.baseUrl}/llinkid/activityplanning/activityplans/activities/attachments`,
    {
      body: data,
      method: 'POST',
    }
  );
  if (!response.ok) {
    throw new Error('Uploaden bijlagen mislukt');
  }
  return response;
}
