import commonUtils from '@kathondvla/sri-client/common-utils';
import { generalSaveError } from '@store/alerts/alertConstants';
import { setAlert } from '@store/alerts/alertsState';
import { fillExpandedClasses, getActivitiesForPlan } from '@store/calendar/calendarHelper';
import { postActivityBatchSaga, postAttachmentsSaga } from '@store/calendar/calendarSagaHelpers';
import { selectActivitiesForPlan } from '@store/calendar/calendarSelectors';
import { selectUngroupedHomePageListIncludingPreviousYearList } from '@store/leerplanList/leerplanListSelectors';
import { getCreatePlanBatch } from '@store/llinkidApis/llinkidApiHelper';
import {
  selectActivitiesData,
  selectHaveActivitiesBeenLoadedRecently,
} from '@store/llinkidApis/llinkidApiSelectors';
import { initActivities, saveLlinkidApiBatch } from '@store/llinkidApis/llinkidApiState';
import {
  selectAllClassesInSchoolyear,
  selectAllCreatorsActiveInSchoolyear,
  selectCurrentSchool,
  selectCurrentSchoolHref,
  selectOrgsTeacherMapForSchoolyear,
} from '@store/userAndSchool/userAndSchoolSelectors';
import { waitForSaga } from '@utils/sagaUtils';
import { getCurriculaForPlan } from '@utils/utils';

import { isEqual } from 'lodash-es';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { setCurrentSchoolyear } from '@store/userAndSchool/usersAndSchoolState';
import { getGoalsToCopy, getCopyCalendarBatch } from './calendarListHelper';
import { selectRecoverCalendarBatch, selectRemoveCalendarBatch } from './calendarListSelectors';
import {
  copyCalendar,
  copyCalendarClose,
  copyCalendarOpen,
  copyCalendarSetOriginalCalendar,
  createCalendar,
  deleteCalendar,
  editCalendar,
  undeleteCalendar,
} from './calendarListState';

function* editCalendarSaga({ payload }) {
  try {
    const { plans, formValues } = payload;

    const batch = [];
    const model = { ...formValues };
    const me = yield select((state) => state.userAndSchools.user);
    const orgs = yield select(selectOrgsTeacherMapForSchoolyear);
    const alreadyCreator = model.teachers.some(
      (e) => e.href === me.$$meta.permalink || orgs[e.href]?.includes(me.$$meta.permalink)
    );

    if (me && !alreadyCreator) {
      // only add me if i'm not already a creator (person or in a group)
      model.teachers = [{ href: me.$$meta.permalink }, ...model.teachers];
    }

    const activityPlans = yield select((state) => state.customCurriculaData.activityPlans);

    for (const cl of model.classes) {
      const workingcopy = plans.find((p) => p.class.href === cl.$$meta.permalink);
      const body = {
        ...activityPlans[workingcopy.key],
        ...{
          creators: model.teachers,
          observers: model.shared ?? null,
          title: model.title,
          curricula: model.curriculum,
        },
      };

      batch.push({
        verb: 'PUT',
        href: `/llinkid/activityplanning/activityplans/${workingcopy.key}`,
        body,
      });

      if (Object.keys(formValues.goalTranslationsMap).length) {
        const activities = yield select((state) =>
          selectActivitiesForPlan(state, activityPlans[workingcopy.key])
        );

        activities.forEach((activity) => {
          if (activity.goals?.length) {
            const goals = getGoalsToCopy(activity.goals, formValues.goalTranslationsMap);
            if (!isEqual(activity.goals, goals)) {
              batch.push({
                verb: 'PUT',
                href: activity.$$meta.permalink,
                body: { ...activity, goals },
              });
            }
          }
        });
      }
    }

    yield postActivityBatchSaga({ payload: { batch, asyncSaving: false } });
  } catch (e) {
    console.error(e.message);
    yield put(setAlert(generalSaveError));
  }
}

function* createCalendarSaga({ payload }) {
  try {
    const batch = getCreatePlanBatch(payload);
    const batchKey = commonUtils.generateUUID();
    yield put(saveLlinkidApiBatch({ batch, batchKey }));
    yield call(
      waitForSaga,
      (state) => state.customCurriculaData.batches[batchKey].isCompleted === true
    );
    const plans = [];
    // get the plan keys for the state go.
    batch.forEach((b) => {
      if (b.href.startsWith('/llinkid/activityplanning/activityplans/')) plans.push(b.body.key);
    });
    globalThis.router.navigate({
      pathname: '/vorderingsplannen',
      search: `ids=${plans.join()}&merge=true`,
    });
  } catch (e) {
    console.error(e.message);
    yield put(setAlert(generalSaveError));
  }
}

function* deleteCalendarSaga({ payload }) {
  try {
    const { keys } = payload;
    const batch = yield select((state) => selectRemoveCalendarBatch(state, { keys }));
    yield postActivityBatchSaga({ payload: { batch, asyncSaving: false } });
  } catch (e) {
    console.error(e.message);
    yield put(setAlert(generalSaveError));
  }
}

function* undeleteCalendarSaga({ payload }) {
  try {
    const { keys } = payload;
    const batch = yield select((state) => selectRecoverCalendarBatch(state, { keys }));
    yield postActivityBatchSaga({ payload: { batch, asyncSaving: false } });
  } catch (e) {
    console.error(e.message);
    yield put(setAlert(generalSaveError));
  }
}

function* copyCalendarSaga({ payload }) {
  try {
    const { originalCalendar, goalTranslationsMap, classes, cloneCalendar, schoolyear } = payload;

    const me = yield select((state) => state.userAndSchools.user);
    const schoolHref = yield select(selectCurrentSchoolHref);
    const orgs = yield select((state) => selectOrgsTeacherMapForSchoolyear(state, { schoolyear }));
    const alreadyCreator = cloneCalendar.teachers.some(
      (e) => e.href === me.$$meta.permalink || orgs[e.href]?.includes(me.$$meta.permalink)
    );

    if (me && !alreadyCreator) {
      // only add me if i'm not already a creator (person or in a group)
      cloneCalendar.teachers = [{ href: me.$$meta.permalink }, ...cloneCalendar.teachers];
    }
    const [batch, attachments] = getCopyCalendarBatch({
      original: originalCalendar,
      copy: cloneCalendar,
      classesMap: classes,
      goalTranslationsMap,
    });

    yield postActivityBatchSaga({ payload: { batch, asyncSaving: false } });
    yield postAttachmentsSaga({ payload: { attachments, asyncSaving: false } });
    yield put(copyCalendarClose());
    yield put(setCurrentSchoolyear(schoolyear));
    yield put(initActivities({ schoolHref })); // refresh activities to contain the correct attachments.
    yield put(
      setAlert({
        key: 'copy-calendar-success',
        msg: `Vorderingsplan gekopiëerd.`,
        type: 'success',
        showClose: true,
        delay: 5000,
      })
    );
  } catch (e) {
    console.error(e.message);
    yield put(setAlert(generalSaveError));
  }
}

function* setPlanDataForCopy({ payload }) {
  const { item } = payload;
  const { plans } = item;
  const school = yield select(selectCurrentSchool);
  const allCreators = yield select(selectAllCreatorsActiveInSchoolyear);
  const leerplanListWithUngrouped = yield select(
    selectUngroupedHomePageListIncludingPreviousYearList
  );
  const curricula = getCurriculaForPlan(leerplanListWithUngrouped, plans[0]);

  const allClasses = yield select(selectAllClassesInSchoolyear);
  const classes = plans
    ?.map((p) => {
      const expandedPlan = fillExpandedClasses(p, allClasses);
      return expandedPlan.class;
    })
    .sort((a, b) => (a.$$displayName > b.$$displayName ? 1 : -1));

  // wait for all the activities to be loaded
  yield call(waitForSaga, (state) => selectHaveActivitiesBeenLoadedRecently(state));

  const allActivities = yield select(selectActivitiesData);
  const plansWithActivities = plans.map((p) => {
    const planActivities = getActivitiesForPlan(p, allActivities);
    return { ...p, activities: planActivities };
  });

  yield put(
    copyCalendarSetOriginalCalendar({
      originalCalendar: {
        ...item,
        plans: plansWithActivities,
        classes,
        curricula,
        school,
        allCreators,
      },
    })
  );
}

export function* watcherSaga() {
  yield takeEvery(editCalendar, editCalendarSaga);
  yield takeEvery(createCalendar, createCalendarSaga);
  yield takeEvery(deleteCalendar, deleteCalendarSaga);
  yield takeEvery(undeleteCalendar, undeleteCalendarSaga);
  yield takeEvery(copyCalendar, copyCalendarSaga);
  yield takeEvery(copyCalendarOpen, setPlanDataForCopy);
}
