import './helpers/sentrySetup'; // keep at the top.
import CalendarWrapper from '@components/calendar/CalendarWrapper';
import CalendarHome from '@components/calendarHome/CalendarHome';
import Home from '@components/home/Home';
import Curriculum from '@components/leerplan/Curriculum';
import GoalsTab from '@components/leerplan/goals/GoalsTab';
import LeerplanHome from '@components/leerplanHome/LeerplanHome';
import LeerplanSelector from '@components/leerplanHome/LeerplanSelector/LeerplanSelector';
import Leidraad from '@components/leidraad/Leidraad';
import RichTextTab from '@components/richTextTab/RichTextTab';
import SchoolsHome from '@components/schoolsHome/SchoolsHome';
// eslint-disable-next-line import/no-extraneous-dependencies
import { InitialEntry } from '@remix-run/router';
import * as Sentry from '@sentry/react';
import { SELECTION_TYPE } from '@store/calendar/calendarConsts';
import { calendarActions, setCalendarKeys, setMerge } from '@store/calendar/calendarState';
import { calendarListActions } from '@store/calendarList/calendarListState';
import { CONTENTAPI_DOCUMENT_KEYS } from '@store/contentApi/contentApiDataAccess';
import { loadCurriculum, loadDocument } from '@store/contentApi/contentApiState';
import { curriculumActions, setUrlParams } from '@store/curriculum/curriculumState';
import { setDistributionMode } from '@store/distribute/distributeState';
import { leerplanListActions } from '@store/leerplanList/leerplanListState';
import { RootState } from '@store/storeSetup';
import { isIframe } from '@utils/utils';
import { createBrowserRouter, createMemoryRouter, Navigate, redirect } from 'react-router-dom';
import { selectCurrentActivityPlans } from '@store/calendar/calendarSelectors';
import { MAIN_TAB_ID } from './constants/leidraadTabs';
import ErrorPage from './error-page';
import Root from './root';
import { stateMatch } from './mocks/helpers/awaitableState';
import { LlinkidStore } from './store';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

async function setCalendarUrlParamsToState(store: LlinkidStore, request) {
  const url = new URL(request.url);
  const queryParams = new URLSearchParams(url.search);

  const ids = (queryParams.get('ids') && queryParams.get('ids')?.split(',')) || [];

  store.dispatch(setCalendarKeys({ calendarKeys: ids }));

  // wait for the activity plans to be loaded.
  // a saga listening to setCalendarKeys to make sure we are in the right school and schoolyear.
  await stateMatch((state: RootState) => selectCurrentActivityPlans(state).length > 0, store);

  if (queryParams.get('merge')) {
    const merge = queryParams.get('merge') === 'true';
    store.dispatch(setMerge({ merge }));
  } else {
    store.dispatch(setMerge({ merge: false }));
  }
}

const routerConfig = (store) => [
  {
    // this is a Layout route, it has no path, so it will always render
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        // this is an index route, it will redirect to /home if you go to the root of the domain
        // or it will rewrite the hashbang url from angularjs to a normal url
        index: true,
        loader: async () => {
          const { location } = window;
          if (location.hash.startsWith('#!/') && location.pathname === '/') {
            // we have an old angularjs url.
            const newPath = location.hash.replace('#!/', '');
            return redirect(`/${newPath}`);
          }
          // these redirects don't work in JEST because JSDOM doesn't propperly implement fetch
          return redirect('/home/leerplan');
        },
      },
      {
        path: 'home',
        element: <Home />,
        children: [
          {
            index: true,
            element: (
              <Navigate to={{ pathname: 'leerplan', search: window.location.search }} replace />
            ),
          },
          {
            index: true,
            path: 'leerplan',
            element: <LeerplanHome />,
          },
          {
            path: 'vorderingsplannen',
            element: <CalendarHome />,
          },
          {
            path: 'leidraad',
            element: <Navigate to={MAIN_TAB_ID} replace />,
          },
          {
            path: 'leidraad/:id',
            element: <Leidraad />,
          },
        ],
      },
      {
        path: 'leerplanlijst',
        element: <LeerplanSelector />,
        loader: async () => {
          store.dispatch(leerplanListActions.setSelectionMode(true));

          return null;
        },
      },
      {
        path: 'minijaarvorderingslijst',
        element: <CalendarHome />,
        loader: async () => {
          store.dispatch(calendarListActions.setSelectionMode(true));

          return null;
        },
      },
      {
        path: 'schools',
        element: <SchoolsHome />,
      },
      {
        path: 'schools/mini',
        element: <SchoolsHome selectionMini={true} />,
      },
      {
        path: 'vorderingsplannen',
        element: <CalendarWrapper />,
        loader: async ({ request }) => {
          await setCalendarUrlParamsToState(store, request);

          return null;
        },
      },
      {
        path: 'vorderingsplannen/miniselector',
        element: <CalendarWrapper selector={true} />,
        loader: async ({ request }) => {
          await setCalendarUrlParamsToState(store, request);

          store.dispatch(calendarActions.setSelectionMode(true));
          store.dispatch(
            calendarActions.setSelectionOptions({ selectionType: SELECTION_TYPE.MINI })
          );

          return null;
        },
      },
      {
        path: 'vorderingsplannen/selector',
        element: <CalendarWrapper selector={true} />,
        loader: async ({ request }) => {
          await setCalendarUrlParamsToState(store, request);

          store.dispatch(calendarActions.setSelectionMode(true));
          store.dispatch(
            calendarActions.setSelectionOptions({ selectionType: SELECTION_TYPE.FULL })
          );

          return null;
        },
      },
      {
        path: 'leerplan/:id',
        element: <Curriculum />,
        loader: async ({ request, params }) => {
          const curriculumKey = params.id;

          if (!curriculumKey) {
            return redirect('/home/leerplan');
          }

          const url = new URL(request.url);
          const queryParams = new URLSearchParams(url.search);
          const refresh = Boolean(queryParams.get('preview'));

          const queryParamsObject = {
            preview: refresh,
            curriculumKey,
            custids: (queryParams.get('custid') && queryParams.get('custid')?.split(',')) || [],
            studids: (queryParams.get('studid') && queryParams.get('studid')?.split(',')) || [],
            groupid: queryParams.get('groupid') || null,
            setid: queryParams.get('setid') || null,
          };

          store.dispatch(setUrlParams(queryParamsObject));

          store.dispatch(loadDocument({ key: CONTENTAPI_DOCUMENT_KEYS.EDUCATIONAL_POINTERS }));
          store.dispatch(loadDocument({ key: CONTENTAPI_DOCUMENT_KEYS.EDUCATIONAL_COMPONENTS }));

          if (curriculumKey !== 'nonderived') {
            // wait for the base curriculum to be loaded.
            store.dispatch(loadCurriculum({ curriculumKey, refresh }));
          }

          return { params: queryParamsObject };
        },

        children: [
          {
            index: true,
            element: (
              <Navigate to={{ pathname: 'doelenlijst', search: window.location.search }} replace />
            ),
          },
          {
            path: 'doelenlijst',
            element: <GoalsTab />,
            loader: () => {
              store.dispatch(setDistributionMode(false));
              return null;
            },
          },
          {
            path: 'distributie',
            element: <GoalsTab />,
            loader: () => {
              store.dispatch(setDistributionMode(true));
              return null;
            },
          },
          {
            path: 'selector',
            element: <GoalsTab selector={true} />,
            loader: () => {
              store.dispatch(setDistributionMode(false));
              store.dispatch(curriculumActions.setSelectionMode(true));
              store.dispatch(
                curriculumActions.setSelectionOptions({
                  allowSubitem: isIframe(),
                  allowOptional: false,
                })
              );
              return null;
            },
          },
          {
            path: 'inleiding',
            element: <RichTextTab tabName={'inleiding'} />,
          },
          {
            path: 'situering',
            element: <RichTextTab tabName={'situering'} />,
          },
          {
            path: 'duiding',
            element: <RichTextTab tabName={'duiding'} />,
          },
          {
            path: 'basisuitrusting',
            element: <RichTextTab tabName={'basisuitrusting'} />,
          },
          {
            path: 'concordantielijst',
            element: <RichTextTab tabName={'concordantielijst'} />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/home/leerplan" replace />,
  },
];

export const routerWithStore = (store) => sentryCreateBrowserRouter(routerConfig(store));

export const memoryRouterForTests = (
  store,
  params?: { initialEntries?: InitialEntry[]; initialIndex?: number }
) => createMemoryRouter(routerConfig(store), params);
