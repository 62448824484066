import * as localCacheSagas from './llinkidApis/localCacheSagas';
import * as userActionsSagas from './llinkidApis/userActionsSagas';
import * as userAndSchoolsSagas from './userAndSchool/userAndSchoolSagas';
import * as contentApiSagas from './contentApi/contentApiSagas';
import * as studyProgrammeSagas from './studyProgrammesApi/StudyProgrammeApiSagas';
import * as leerplanListSagas from './leerplanList/leerplanListSagas';
import * as leidraadSagas from './leidraad/leidraadSagas';
import * as calendarListSagas from './calendarList/calendarListSagas';
import * as curriculumSagas from './curriculum/curriculumSagas';
import * as calendarSagas from './calendar/calendarSagas';
import * as alertSagas from './alerts/alertSagas';
import * as routingSagas from './llinkidApis/routingSagas';
import * as persistStateSagas from './llinkidApis/persistStateSagas';

export function initSagas(sagaMiddleware) {
  const allSagas = [
    localCacheSagas,
    userActionsSagas,
    userAndSchoolsSagas,
    contentApiSagas,
    studyProgrammeSagas,
    leerplanListSagas,
    leidraadSagas,
    calendarListSagas,
    curriculumSagas,
    calendarSagas,
    alertSagas,
    routingSagas,
    persistStateSagas,
  ];

  // we connect all the sagas that start with 'watch' to the sagaMiddleware
  allSagas.forEach((sagas) => {
    const hasWatchSaga = Object.keys(sagas).some((key) => key.startsWith('watch'));

    if (!hasWatchSaga) {
      console.error(
        'No watch... saga found in imported saga file. only found: ',
        Object.keys(sagas)
      );
    }

    Object.entries(sagas).forEach(([key, saga]) => {
      if (key.startsWith('watch')) {
        sagaMiddleware.run(saga);
      }
    });
  });
}
