import { generalSaveError } from '@store/alerts/alertConstants';
import { setAlert } from '@store/alerts/alertsState';
import {
  selectCreatorFromCustomCurricula,
  selectGoalList,
  selectGoalsAndSectionAndGoalListFromCurriculum,
  selectLayeredAnnotationsObjFromUrlOrParams,
} from '@store/curriculum/curriculumSelectors';
import { getReadOrder } from '@utils/utils';
import { eventChannel } from 'redux-saga';
import { call, delay, put, race, select, take, takeEvery } from 'redux-saga/effects';
import { getPositioningObject } from '@utils/annotationHelper';
import { createCustomItem, CUSTOMCREATIONTYPES, CUSTOMTYPES } from '@utils/curriculumHelper';
import { selectCurrentSchoolHref } from '@store/userAndSchool/userAndSchoolSelectors';
import settings from '@config/settings';
import { BatchRequest } from '../../types/sriTypes';
import { excludeItem, generateCustomItemBatch, moveItemHelper } from './llinkidApiHelper';
import {
  addCustomGoal,
  addCustomSection,
  changeItemTitle,
  completeDistributionOrDeleteItem,
  distributeGoal,
  editCustomGoal,
  includeItem,
  moveItem,
  referenceGoal,
  saveLlinkidApiBatch,
  setAutoSync,
} from './llinkidApiState';
import {
  selectAddCustomSectionBatch,
  selectDistributeGoalBatch,
  selectDistributeGoalFromAnnotationsBatch,
} from './userActionsSelectors';
import {
  AddCustomGoalPayloadAction,
  AddCustomSectionPayloadAction,
  ReferenceGoalPayloadAction,
} from './llinkidApiTypes';

function* includeItemSaga(action) {
  const { item } = action.payload;
  const { layeredAnnotationsObj } = yield select((state) =>
    selectLayeredAnnotationsObjFromUrlOrParams(state)
  );

  try {
    const batch = excludeItem(item, layeredAnnotationsObj) as BatchRequest;
    yield put(saveLlinkidApiBatch({ batch, applyInstantly: true }));
  } catch (e) {
    console.error(e.message);
    yield put(setAlert(generalSaveError));
  }
}

function* completeDistributionOrDeleteItemSaga(action) {
  const { item } = action.payload;

  try {
    const batch: BatchRequest = yield select((state) =>
      selectDistributeGoalFromAnnotationsBatch(state, {
        goalsPositions: [
          {
            goal: item,
            position: { readOrder: getReadOrder(item) },
          },
        ],
      })
    );
    yield put(saveLlinkidApiBatch({ batch, applyInstantly: true }));
  } catch (e) {
    console.error(e.message);
    yield put(setAlert(generalSaveError));
  }

  // yield put(saveLlinkidApiBatch({ batch, applyInstantly: true }));
}

function* distributeGoalSaga(action) {
  const { selectedCurr, goalHref } = action.payload;
  try {
    const batch = yield select((state) =>
      selectDistributeGoalBatch(state, { resources: selectedCurr, goalHref })
    );

    yield put(saveLlinkidApiBatch({ batch, applyInstantly: true }));
  } catch (e) {
    console.error(e.message);
    yield put(setAlert(generalSaveError));
  }
}

function* moveItemSaga(action) {
  try {
    const { parent, sibling, item } = action.payload;
    const { layeredAnnotationsObj } = yield select((state) =>
      selectLayeredAnnotationsObjFromUrlOrParams(state)
    );
    const { curriculumKey, setid, studids, custids } = yield select((state) => state.curriculum);
    const list = yield select((state) =>
      // @ts-expect-error not sure why it's complaining. code seems fine.
      selectGoalsAndSectionAndGoalListFromCurriculum(state, {
        curriculumKey,
        setid,
        studids,
        custids,
      })
    );

    let fullParent = list.find((e) => e.href === parent);
    const fullItem = list.find((e) => e.href === item);

    let siblings = {
      above: undefined,
      below: undefined,
      readOrder: 1,
    };

    if (fullParent?.$$meta.permalink === '/content/FOUNDATIONALPLACEHOLDER') {
      const goalList = yield select(selectGoalList);
      fullParent = goalList;
    }

    if (sibling) {
      const siblingIndex = fullParent?.goals?.findIndex((g) => g.href === sibling);
      const above = fullParent?.goals?.[siblingIndex];
      const below = fullParent?.goals?.[siblingIndex + 1];

      siblings = {
        above,
        below,
        readOrder: 1,
      };
    } else if (fullParent?.goals?.length) {
      const [firstChild] = fullParent.goals;
      siblings.below = firstChild;
    }

    const batch = moveItemHelper({
      draggedItem: { href: fullItem.href, annotation: fullItem.annotation },
      parent: { href: fullParent.href, annotation: fullParent.annotation },
      siblings,
      layeredAnnotationsObj,
    }) as BatchRequest;

    yield put(saveLlinkidApiBatch({ batch, applyInstantly: true }));
  } catch (e) {
    console.error(e.message);
    yield put(setAlert(generalSaveError));
  }
}

function* changeTitleItemSaga(action) {
  const { title, item } = action.payload;
  const { key } = item;

  const batchBody = {
    $$meta: item.$$meta,
    creator: item.creator,
    context: item.context,
    type: item.type,
    key: item.key,
    description: title,
  };

  const batch = [
    { verb: 'PUT', href: `/llinkid/customcurriculum/customitems/${key}`, body: batchBody },
  ] as BatchRequest;

  yield put(saveLlinkidApiBatch({ batch, applyInstantly: true }));
}

function* editCustomGoalSaga(action) {
  const goal = action.payload;
  const { key } = goal;

  const batchBody = {
    $$meta: goal.$$meta,
    creator: goal.creator,
    context: goal.context,
    type: goal.type,
    key: goal.key,
    identifier: goal.identifier,
    description: goal.description,
    mandatory: goal.mandatory,
  };

  const batch = [
    { verb: 'PUT', href: `/llinkid/customcurriculum/customitems/${key}`, body: batchBody },
  ] as BatchRequest;

  yield put(saveLlinkidApiBatch({ batch, applyInstantly: true }));
}

function* referenceGoalSaga(action: ReferenceGoalPayloadAction) {
  const { goals, parent } = action.payload;
  // /find the above and below in the VM, the only info we have is item.parent which is basically the originator, the goal or item where the modal was opened.
  // /calculate the positions for the list of item.goals.
  // /alter the position object to have a readOrder.

  const goalListItems = yield select(selectGoalsAndSectionAndGoalListFromCurriculum);

  const positions = getPositioningObject(
    parent,
    goals,
    CUSTOMCREATIONTYPES.llinkidGoal,
    goalListItems
  );

  const resultBatch: BatchRequest = yield select((state) =>
    selectDistributeGoalFromAnnotationsBatch(state, {
      goalsPositions: goals.map((goal, index) => ({ goal, position: positions[index] })),
    })
  );
  yield put(saveLlinkidApiBatch({ batch: resultBatch, applyInstantly: true }));
}

function* addCustomGoalSaga(action: AddCustomGoalPayloadAction) {
  const { parentItem, customItem } = action.payload;
  const schoolHref: string = yield select(selectCurrentSchoolHref);
  const creatorHref: string = yield select(
    (state) => selectCreatorFromCustomCurricula(state)?.href
  );

  const customItemProperties = {
    ...customItem,
    type: CUSTOMTYPES.goal,
  };
  const customItemObject = createCustomItem(schoolHref, creatorHref, customItemProperties);

  const batch = generateCustomItemBatch(customItemObject);

  const goalListItems = yield select(selectGoalsAndSectionAndGoalListFromCurriculum);

  const positions = getPositioningObject(
    parentItem,
    [customItemObject],
    CUSTOMCREATIONTYPES.llinkidGoal,
    goalListItems
  );

  const resultBatch: BatchRequest = yield select((state) =>
    selectDistributeGoalFromAnnotationsBatch(state, {
      goalsPositions: [{ goal: customItemObject, position: positions[0] }],
    })
  );
  yield put(saveLlinkidApiBatch({ batch: [...resultBatch, ...batch], applyInstantly: true }));
}

function* addCustomSectionSaga(action: AddCustomSectionPayloadAction) {
  const { parent, type } = action.payload;
  const batch: BatchRequest = yield select((state) =>
    selectAddCustomSectionBatch(state, {
      parentItem: parent,
      creationType: type,
    })
  );
  yield put(saveLlinkidApiBatch({ batch, applyInstantly: true }));
}

export function* watchUserActionsSaga() {
  yield takeEvery(includeItem, includeItemSaga);
  yield takeEvery(moveItem, moveItemSaga);
  yield takeEvery(completeDistributionOrDeleteItem, completeDistributionOrDeleteItemSaga);
  yield takeEvery(changeItemTitle, changeTitleItemSaga);
  yield takeEvery(editCustomGoal, editCustomGoalSaga);
  yield takeEvery(distributeGoal, distributeGoalSaga);
  yield takeEvery(referenceGoal, referenceGoalSaga);
  yield takeEvery(addCustomGoal, addCustomGoalSaga);
  yield takeEvery(addCustomSection, addCustomSectionSaga);
}

function createIdleChannel() {
  return eventChannel((emitter) => {
    const events = [
      'load',
      'mousemove',
      'mousedown',
      'touchstart',
      'touchmove',
      'click',
      'keydown',
      'scroll',
    ];

    const userActive = () => {
      emitter('active');
    };

    events.forEach((event) => {
      window.addEventListener(event, userActive);
    });

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, userActive);
      });
    };
  });
}

export function* watchUserIdleSaga() {
  const idleTimeout = settings.cacheTimeout.userIdleTime * 1000; // time is in milliseconds
  const idleChannel = yield call(createIdleChannel);

  let userIsIdle = false;

  while (true) {
    const { timeout } = yield race({
      timeout: delay(idleTimeout),
      activity: take(idleChannel),
    });

    if (timeout) {
      userIsIdle = true;
      yield put(setAutoSync(false));
      console.log('user is idle');
    } else if (userIsIdle) {
      userIsIdle = false;
      yield put(setAutoSync(true));
      console.log('user is no longer idle');
    }
  }
}
