import commonUtils from '@kathondvla/sri-client/common-utils';
import { setAlert } from '@store/alerts/alertsState';
import { selectPendingBatches } from '@store/llinkidApis/llinkidApiSelectors';
import {
  addPendingBatch,
  applyBatch,
  initActivities,
  removePendingBatch,
  setPendingBatchesStatus,
} from '@store/llinkidApis/llinkidApiState';
import { FileBatch, FileUploadMeta, PendingBatchItem } from '@store/llinkidApis/llinkidApiTypes';
import { RootState } from '@store/storeSetup';
import {
  selectCurrentSchoolHref,
  selectCurrentSchoolyearKey,
} from '@store/userAndSchool/userAndSchoolSelectors';
import { sleep } from '@utils/sharedutils';
import { put, select } from 'redux-saga/effects';
import settings from '@config/settings';
import { COMPLETE, ERROR_AND_RETRY, UPLOADING_FILE, UPLOADING_ITEM } from '../../constants/status';
import { BatchRequest } from '../../types/sriTypes';
import {
  getNewDelay,
  handleBatchItem,
  handleFileBatchItem,
  isBatchUseful,
} from './asyncSavingHelper';

export function* postActivityBatchSaga(action: {
  payload: { asyncSaving: boolean; batch: BatchRequest };
}) {
  const { batch, asyncSaving } = action.payload;
  let success = false;

  if (asyncSaving === true) {
    const batchKey = commonUtils.generateUUID();
    yield put(setPendingBatchesStatus({ status: UPLOADING_ITEM.status }));
    yield put(addPendingBatch({ batchKey, batch }));
    success = true;
  } else {
    success = yield handleBatchItem(batch);
  }

  if (success) {
    yield put(applyBatch({ batch }));
  } else {
    throw new Error('Opslaan mislukt');
  }
}

export function* postAttachmentsSaga(action: {
  payload: {
    attachments: { items: Array<FileUploadMeta>; files: File[] };
    asyncSaving: boolean;
  };
}) {
  const { attachments, asyncSaving } = action.payload;
  const { items, files } = attachments;
  if (items.length === 0) return;

  if (settings.enableDemoMode === true) {
    yield put(
      setAlert({
        key: 'demoMode',
        type: 'warning',
        title: 'Demo modus',
        msg: 'Bijlagen toevoegen aan een activiteit is uitgeschakeld in demo mode',
        showClose: true,
        delay: 10000,
      })
    );
    return;
  }

  if (items.length > 0) {
    const data: FileBatch = { files, body: items };
    if (asyncSaving) {
      yield put(addPendingBatch({ batchKey: commonUtils.generateUUID(), fileBatch: data }));
    } else {
      yield handleFileBatchItem(data);
      yield sleep(500); // give the API some time
      const schoolHref = yield select(selectCurrentSchoolHref);
      const schoolyear = yield select(selectCurrentSchoolyearKey);
      yield put(initActivities({ schoolHref, schoolyear })); // update local cached activities with the new attachments.
    }
  }
}

function* processBatchItem(
  currentBatch: PendingBatchItem,
  nextBatch: PendingBatchItem | undefined
) {
  // check if this batch will not be pointless when the next batch will basically PUT all the same items again (can happen when moving weeks)
  if (currentBatch.batch && isBatchUseful(currentBatch.batch, nextBatch?.batch)) {
    yield put(setPendingBatchesStatus({ status: UPLOADING_ITEM.status }));
    yield handleBatchItem(currentBatch.batch);
    // throw new Error('batch post failed'); // uncomment to test error handling
    yield sleep(500); // give the API some time
  } else if (currentBatch.fileBatch) {
    yield put(setPendingBatchesStatus({ status: UPLOADING_FILE.status }));
    yield handleFileBatchItem(currentBatch.fileBatch);
    yield sleep(500); // give the API some time
    const schoolHref = yield select(selectCurrentSchoolHref);
    const schoolyear = yield select(selectCurrentSchoolyearKey);
    yield put(initActivities({ schoolHref, schoolyear })); // update local cached activities with the new attachments.
  }
  yield put(removePendingBatch({ batchKey: currentBatch.batchKey }));
}

export function* processPendingBatches() {
  let pendingBatches: Array<PendingBatchItem> = yield select((state: RootState) =>
    selectPendingBatches(state)
  );
  let delayMs = settings.asyncSaving.minDelay;
  while (pendingBatches.length) {
    console.log(pendingBatches);
    const currentBatch = pendingBatches[0];
    const nextBatch: PendingBatchItem | undefined = pendingBatches[1];
    try {
      yield processBatchItem(currentBatch, nextBatch);
    } catch (err) {
      console.error(err);
      delayMs = getNewDelay(delayMs, true);
      yield put(setPendingBatchesStatus({ status: ERROR_AND_RETRY.status, delay: delayMs / 1000 }));
      yield sleep(delayMs);
    }
    pendingBatches = yield select((state: RootState) => selectPendingBatches(state));
  }

  yield put(setPendingBatchesStatus({ status: COMPLETE.status }));
}
