import { combineReducers, configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import createSagaMiddleware from 'redux-saga';
import { PathParams, RestRequest } from 'msw';
import settings from '@config/settings';
import alertsState from './alerts/alertsState';
import calendarState from './calendar/calendarState';
import calendarListState from './calendarList/calendarListState';
import contentApiDataState, {
  initCurriculaRelations,
  initCurriculaRoots,
  setCurriculaRelations,
  setCurriculaRoots,
} from './contentApi/contentApiState';
import curriculumState from './curriculum/curriculumState';
import distributeState from './distribute/distributeState';
import leerplanListState from './leerplanList/leerplanListState';
import customCurriculaState, {
  deltaUpdateCache,
  setStateFromFile,
} from './llinkidApis/llinkidApiState';
import { initSagas } from './saga';
import studyProgrammeApiDataState, {
  init as initStudyProgrammeApiData,
  setAllData,
} from './studyProgrammesApi/studyProgrammesApiState';
import usersAndSchoolState, {
  init as initUsersAndSchoolData,
  orgsFetched,
  responsibilitiesInSchoolFetched,
  teachersForTeamsFetched,
  teachersInSchoolFetched,
} from './userAndSchool/usersAndSchoolState';
import { worker } from '../mocks/browser';

const actionSanitizer = (action) => {
  if (setStateFromFile.match(action)) {
    const newData: Record<string, string> = {};
    const data = action.payload.customCurriculaData;
    if (data === null) {
      return action;
    }

    Object.keys(data).forEach((key) => {
      if (key in data) {
        newData[key] = `<< ommitted: ${Object.keys(data[key]).length} items >>`;
      }
    });

    return {
      ...action,
      payload: {
        ...action.payload,
        customCurriculaData: {
          ...newData,
          settings: data.settings,
        },
      },
    };
  }

  if (deltaUpdateCache.match(action)) {
    return {
      ...action,
      payload: {
        ...action.payload,
        items: `List of ${action.payload.items.length} items`,
      },
    };
  }

  if (teachersForTeamsFetched.match(action)) {
    return {
      ...action,
      payload: {
        ...action.payload,
        allTeamResps: `List of ${action.payload.allTeamResps.length} items`,
      },
    };
  }

  if (teachersInSchoolFetched.match(action)) {
    return {
      ...action,
      payload: {
        ...action.payload,
        teachers: `List of ${action.payload.teachers.length} items`,
      },
    };
  }

  if (responsibilitiesInSchoolFetched.match(action)) {
    return {
      ...action,
      payload: {
        ...action.payload,
        responsibilities: `List of ${action.payload.responsibilities.length} items`,
      },
    };
  }

  if (setCurriculaRelations.match(action)) {
    return {
      ...action,
      payload: `List of ${action.payload.length} items`,
    };
  }

  if (setAllData.match(action) || setCurriculaRoots.match(action) || orgsFetched.match(action)) {
    return {
      ...action,
      payload: `List of ${action.payload.length} items`,
    };
  }

  return action;
};

const stateSanitizer = (state) => {
  const { documents, curricula } = state.contentApiData;

  const documentsSimplified = { ...documents };
  Object.keys(documentsSimplified).forEach((key) => {
    documentsSimplified[key] = `<< ${documentsSimplified[key].length} items >>`;
  });
  const curriculaSimplified = { ...curricula };
  Object.keys(curriculaSimplified).forEach((key) => {
    curriculaSimplified[key] = `<< ommitted >>`;
  });

  return {
    ...state,
    customCurriculaData: {
      ...state.customCurriculaData,
      customCurricula: `<< ${
        Object.keys(state.customCurriculaData.customCurricula).length
      } items >>`,
      annotations: `<< ${Object.keys(state.customCurriculaData.annotations).length} items >>`,
      activityPlans: `<< ${Object.keys(state.customCurriculaData.activityPlans).length} items >>`,
      activities: `<< ${Object.keys(state.customCurriculaData.activities).length} items >>`,
      customCurriculaGroups: `<< ${
        Object.keys(state.customCurriculaData.customCurriculaGroups).length
      } items >>`,
      customItems: `<< ${Object.keys(state.customCurriculaData.customItems).length} items >>`,
    },
    contentApiData: {
      ...state.contentApiData,
      curriculaRelations: `<< ${state.contentApiData.curriculaRelations.length} items >>`,
      curriculaRoots: `<< ${state.contentApiData.curriculaRoots.length} items >>`,
      curriculaGoalLists: `<< ${state.contentApiData.curriculaGoalLists.length} items >>`,
      goals: `<< ${Object.keys(state.contentApiData.goals).length} items >>`,
      documents: documentsSimplified,
      curricula: curriculaSimplified,
    },
    studyProgrammeApiData: {
      ...state.studyProgrammeApiData,
      allPrograms: `<< ${state.studyProgrammeApiData.allPrograms.length} items >>`,
    },
  };
};

const actionLoggerMiddleware = () => (next) => (action) => {
  if (typeof process !== 'undefined' && process?.env?.VITEST) {
    // IF YOU WANT TO LOG ALL ACTIONS IN THE CONSOLE DURING TESTING
    // console.log('action: ', action.type);
  }
  return next(action);
};

const sentryStateTransformer = (state) => {
  const cleanedState = stateSanitizer(state);

  const sentryState = {
    ...cleanedState,
    userAndSchools: {
      ...cleanedState.userAndSchools,
      schoolsData: null,
    },
  };
  return sentryState;
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: actionSanitizer,
  stateTransformer: sentryStateTransformer,
});

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  leerplanList: leerplanListState.reducer,
  calendarList: calendarListState.reducer,
  calendar: calendarState.reducer,
  customCurriculaData: customCurriculaState.reducer,
  userAndSchools: usersAndSchoolState.reducer,
  contentApiData: contentApiDataState.reducer,
  studyProgrammeApiData: studyProgrammeApiDataState.reducer,
  curriculum: curriculumState.reducer,
  distribute: distributeState.reducer,
  alerts: alertsState.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const initState = (store) => {
  store.dispatch(initStudyProgrammeApiData());
  store.dispatch(initUsersAndSchoolData());

  store.dispatch(initCurriculaRoots());
  store.dispatch(initCurriculaRelations());
};

const setupStore = (preloadedState?: any) => {
  // the preloadedState should be Partial<RootState>, but it's complaining, maybe because not all slices are typed  yet?
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        // thunk: false,
        serializableCheck:
          process.env.NODE_ENV === 'test'
            ? false
            : {
                ignoredPaths: [
                  'customCurriculaData.customCurricula',
                  'customCurriculaData.annotations',
                  'customCurriculaData.activityPlans',
                  'customCurriculaData.activities',
                  'customCurriculaData.customCurriculaGroups',
                  'userAndSchools.schoolsData',
                ],
                ignoredActions: [],
              },
      })
        .concat(sagaMiddleware)
        .concat(actionLoggerMiddleware);
    },
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers({
        autoBatch: { type: 'tick' },
      }).concat(sentryReduxEnhancer);
    },
    preloadedState,
    devTools: {
      // options as if you were setting it up by hand
      // https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#windowdevtoolsextensionconfig
      stateSanitizer,
      actionSanitizer,
    },
  });
  initSagas(sagaMiddleware);

  if (
    (process.env.NODE_ENV === 'development' || settings.enableDemoMode) &&
    settings.enableMswInBrowser &&
    process.env.VITEST === undefined
  ) {
    const workerConfig = {
      // turn off MSW warnings for specific routes
      onUnhandledRequest(req: RestRequest<never, PathParams<string>>, print) {
        if (req.url.pathname.startsWith('/assets/')) {
          return;
        }

        if (
          req.url.hostname.includes('sentry.io') ||
          req.url.hostname.includes('google-analytics')
        ) {
          return;
        }

        // specify routes to exclude
        const excludedRoutes = [
          '/dist/img/logo_ko_vl.png',
          '/favicon.ico',
          '/sockjs-node/info',
          '/resource/errors_nl.json',
        ];

        // check if the req.url.pathname contains excludedRoutes
        const isExcluded = excludedRoutes.some(
          (route) => req.url.pathname.includes(route) || req.url.pathname.startsWith('/')
        );

        if (isExcluded) {
          return;
        }

        print.warning();
      },
    };
    // @ts-expect-error some complaints about the config
    worker.start(workerConfig); // Set settings.enableMswInBrowser: true when you want to develop with MSW or to check if any requests are missing.
  }
  if (typeof process === 'undefined' || process.env.VITEST === undefined) {
    // disable these inits for tests
    initState(store);
  }

  return store;
};

export default setupStore;
