const apisAndUrls = {
  cachedApi: {
    baseUrl: "https://acccached-api.katholiekonderwijs.vlaanderen",
  },
  api: "https://accapi.katholiekonderwijs.vlaanderen",
  customcurapi: {
    baseUrl: "https://acccustomcurricula-api.katholiekonderwijs.vlaanderen",
    groupBy: 70,
  },
  activityplansapi: {
    baseUrl: "https://accactivityplans-api.katholiekonderwijs.vlaanderen",
  },
  securityapi: {
    baseUrl: "https://accbeveiliging-nodejs.katholiekonderwijs.vlaanderen",
  },
  myVsko: "http://accmijn.katholiekonderwijs.vlaanderen",
};

export default apisAndUrls;
