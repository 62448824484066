import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectArePlanGoalsLoaded,
  selectDisappearedGoalsInPlans,
  selectGoalsFromPlans,
  selectMissingGoals,
  selectSelectedCurriculumLoaded,
} from '@store/calendar/calendarSelectors';
import { setAlert } from '@store/alerts/alertsState';
import { loadGoalsForPlans, loadTranslationsToNewCurricula } from '@store/calendar/calendarState';
import { getParamsFromCurriculum } from '@utils/utils';
import { RootState } from '@store/storeSetup';
import useOnceCall from './useOnceCall';

type MissingGoalsType = {
  missingGoals: Array<{
    href: string;
  }>;
  usedGoals: any[]; // these are actual goals with a disappeared boolean
  goalTranslationsMap: Record<string, string>;
  loading: boolean;
  usedGoalsLoading: boolean;
};

const DEFAULT_STATE: MissingGoalsType = {
  missingGoals: [],
  usedGoals: [],
  goalTranslationsMap: {},
  loading: true,
  usedGoalsLoading: true,
};

const useMissingGoals = (plans, curricula, schoolyear, currentSchoolHref) => {
  const missingGoalsRef = useRef<HTMLElement | null>(null);
  const [missingGoals, setMissingGoals] = useState<MissingGoalsType>(DEFAULT_STATE);
  const [selectedCurriculumParams, setselectedCurriculaParams] = useState([]);
  const [planCurriculumParams, setPlanCurriculaParams] = useState([]);
  const dispatch = useDispatch();

  const planSchoolHref = plans?.[0]?.context.href;
  const areWeInThePlanContext = planSchoolHref === currentSchoolHref;

  const disappearedGoals = useSelector((state: RootState) =>
    areWeInThePlanContext ? selectDisappearedGoalsInPlans(state, { plans }) : null
  );

  const goalsFromPlans = useSelector((state: RootState) =>
    areWeInThePlanContext ? selectGoalsFromPlans(state, { plans }) : null
  );

  const missingGoalsRes = useSelector((state: RootState) =>
    selectMissingGoals(state, {
      selectedCurriculum: selectedCurriculumParams,
      usedGoals: missingGoals.usedGoals,
      originalCurriculum: planCurriculumParams,
      schoolyear,
    })
  );

  const selectedCurriculaLoaded = useSelector((state: RootState) =>
    missingGoals.usedGoals?.length
      ? selectSelectedCurriculumLoaded(state, {
          selectedCurriculum: selectedCurriculumParams,
          usedGoals: missingGoals.usedGoals,
          originalCurriculum: planCurriculumParams,
          schoolyear,
        })
      : true
  );

  const planGoalsLoaded = useSelector((state: RootState) =>
    selectArePlanGoalsLoaded(state, { plans })
  );

  const setMissingGoalsHandler = async (value) => {
    setselectedCurriculaParams(
      value?.map((e) => {
        return getParamsFromCurriculum(e, schoolyear);
      }) ?? []
    );
  };

  useEffect(() => {
    setPlanCurriculaParams(
      curricula?.map((e) => {
        return getParamsFromCurriculum(e, schoolyear);
      })
    );
  }, [curricula, schoolyear]);

  useOnceCall(() => {
    console.log('disappearedGoals', disappearedGoals);
    if (!disappearedGoals?.length) return;
    const alert = {
      key: 'missing-goals-in-orig-plan-alert',
      title: 'Ontbrekende doelen',
      msg: `De volgende doelen ontbreken reeds in het gekoppelde leerplan: ${disappearedGoals
        .map((href) => goalsFromPlans.find((z) => z.href === href)?.identifier)
        .join(', ')}`,
      type: 'warning',
      showClose: true,
      delay: 10000,
    };
    dispatch(setAlert(alert));
  }, areWeInThePlanContext && plans && planGoalsLoaded && goalsFromPlans?.length && disappearedGoals?.length);

  useEffect(() => {
    // console.log('EFFECT', { areWeInThePlanContext, plans, planGoalsLoaded });
    if (areWeInThePlanContext && plans && planGoalsLoaded) {
      console.log('goalsFromPlans', goalsFromPlans);
      setMissingGoals((prev) => ({ ...prev, usedGoals: goalsFromPlans, usedGoalsLoading: false }));
    }
  }, [areWeInThePlanContext, plans, planGoalsLoaded, goalsFromPlans]);

  useEffect(() => {
    if (plans && planGoalsLoaded && missingGoals.usedGoals?.length) {
      dispatch(
        // TODO: this is still dispatched too often
        loadTranslationsToNewCurricula({
          selectedCurriculum: selectedCurriculumParams,
          usedGoals: missingGoals.usedGoals,
          originalCurriculum: planCurriculumParams,
          schoolyear,
        })
      );
    }
  }, [
    dispatch,
    selectedCurriculumParams,
    plans,
    planGoalsLoaded,
    missingGoals.usedGoals,
    planCurriculumParams,
    schoolyear,
  ]);

  // useEffect(() => {
  //   console.log('missingGoals', missingGoals);
  // }, [missingGoals]);

  useEffect(() => {
    // console.log('missingGoalsSelectorResult', missingGoalsRes, 'loaded', selectedCurriculaLoaded);
    const isLoading = !selectedCurriculaLoaded;
    if (isLoading !== missingGoals.loading) {
      setMissingGoals((prev) => ({ ...prev, loading: isLoading }));
    }

    if (selectedCurriculaLoaded && missingGoalsRes) {
      setMissingGoals((prev) => ({ ...prev, ...missingGoalsRes }));
      if (missingGoalsRes.missingGoals?.length)
        missingGoalsRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [missingGoalsRes, selectedCurriculaLoaded, missingGoals.loading]);

  useEffect(() => {
    if (plans) {
      // console.log({ plans });
      dispatch(loadGoalsForPlans({ plans }));
    }
  }, [plans, dispatch]);

  return [missingGoals, setMissingGoalsHandler, missingGoalsRef] as const;
};

export default useMissingGoals;
