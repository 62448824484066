import { takeEvery, select, call, put, all } from 'redux-saga/effects';
import { loadGoalsFromParamsSaga, waitForSaga } from '@utils/sagaUtils';
import { selectIsLlinkidInitializing } from '@store/llinkidApis/llinkidApiSelectors';
import { loadGoals, loadGoalsCorrelations } from '@store/contentApi/contentApiState';

import { getKeyFromHref } from '@utils/getKeyFromHref';
import { setAlert } from '@store/alerts/alertsState';
import { generalLoadError, generalSaveError } from '@store/alerts/alertConstants';
import { saveLlinkidApiBatch } from '@store/llinkidApis/llinkidApiState';
import { getMoveWeekBatch } from './calendarHelper';
import { postActivityBatchSaga, postAttachmentsSaga } from './calendarSagaHelpers';
import {
  selectBatchFromEditModal,
  selectActiveCustomCurriculaParamsFromPlans,
  selectExtraGoalsToLoadFromPlans,
  selectActivitiesForPlans,
} from './calendarSelectors';
import {
  deleteActivity,
  editActivityModalClose,
  loadGoalsForPlans,
  loadTranslationsToNewCurricula,
  moveWeek,
  saveActivity,
  saveActivityFailed,
  setCalendarKeys,
} from './calendarState';
import { DeleteActivityPayload, MoveWeekPayload } from './calendarTypes';
import { BatchRequest } from '../../types/sriTypes';

function* loadExtraData(action) {
  try {
    const { plans } = action.payload;
    yield call(waitForSaga, (state) => selectIsLlinkidInitializing(state) === false);
    yield call(
      waitForSaga,
      (state) =>
        Object.keys(state.customCurriculaData.activityPlans).length &&
        Object.keys(state.customCurriculaData.activities).length &&
        Object.keys(state.customCurriculaData.customCurricula).length
    );
    const curriculaParams = yield select(
      (state) => selectActiveCustomCurriculaParamsFromPlans(state, { plans }) // this is generating an error on refresh
    );
    const refresh = yield select((state) => state.curriculum.preview);
    // for the copy modal, we don't need ALL the goals of the selected curricula, we only need the ones that have been included in the activities.
    // however, when opening the calendar, we WILL need all the goals. so we have used the same logic for simplicity.
    if (curriculaParams) {
      yield all(curriculaParams.map((params) => loadGoalsFromParamsSaga(params)));
    }

    const extraGoalsFromPlan = yield select((state) =>
      selectExtraGoalsToLoadFromPlans(state, { plans })
    );

    if (extraGoalsFromPlan.length) {
      yield put(loadGoals({ keys: extraGoalsFromPlan.map((e) => getKeyFromHref(e)), refresh }));
    }
  } catch (e) {
    console.error(e.message);
    yield put(setAlert(generalLoadError));
  }
}

function* loadCurricula(action) {
  try {
    const newAction = {
      payload: {
        plans: action.payload.calendarKeys.map((e) => {
          return { key: e };
        }),
      },
    };
    yield loadExtraData(newAction);
  } catch (e) {
    console.error(e.message);
    yield put(setAlert(generalLoadError));
  }
}

function* loadTranslationData(action) {
  try {
    const { selectedCurriculum, originalCurriculum } = action.payload;

    if (!originalCurriculum) return;

    const shouldLoadCorrelation = yield select(
      (state) =>
        !state.contentApiData.goalsCorrelationsLoading &&
        Object.keys(state.contentApiData.goalsCorrelations).length === 0
    );

    if (shouldLoadCorrelation) {
      yield put(loadGoalsCorrelations());
    }

    yield all(selectedCurriculum.map((params) => loadGoalsFromParamsSaga(params)));
  } catch (e) {
    console.error(e.message);
    yield put(setAlert(generalLoadError));
  }
}

function* deleteAllActivity(action: DeleteActivityPayload) {
  try {
    const { parentHrefs } = action.payload;

    const batch: BatchRequest = [];
    const parents = new Set<string>(parentHrefs);
    parentHrefs.forEach((href) => batch.push({ verb: 'DELETE', href }));

    const activities = yield select((state) => Object.values(state.customCurriculaData.activities));
    const children = activities.filter((act) => parents.has(act.parent?.href));
    children.forEach((activity) => batch.push({ verb: 'DELETE', href: activity.$$meta.permalink })); // Do we want to delete att?
    yield put(saveLlinkidApiBatch({ batch, applyInstantly: true }));
  } catch (e) {
    console.error(e.message);
    yield put(setAlert(generalSaveError));
  }
}

function* saveActivitySaga(action) {
  try {
    const modalData = action.payload;

    const [batch, attachments] = yield select((state) =>
      selectBatchFromEditModal(state, modalData)
    );

    yield postActivityBatchSaga({ payload: { batch, asyncSaving: false } });
    // yield delay(2000);
    // throw new Error('fail');
    yield postAttachmentsSaga({ payload: { attachments, asyncSaving: false } });
    yield put(editActivityModalClose());
    yield put(
      setAlert({
        key: 'edit-activity-success',
        msg: `Activiteit ${modalData.isCreate ? 'aangemaakt' : 'aangepast'}.`,
        type: 'success',
        showClose: true,
        delay: 2000,
      })
    );
  } catch (e) {
    console.error(e.message);
    yield put(
      setAlert({
        key: 'edit-activity-error',
        msg: `Er is een probleem opgetreden bij het bewaren. Probeer even later opnieuw.`,
        title: 'Fout',
        type: 'error',
        showClose: true,
        delay: 10000,
      })
    );
    yield put(saveActivityFailed());
  }
}

function* moveWeekSaga(action: MoveWeekPayload) {
  try {
    const { startDate, type } = action.payload;

    const allActivities = yield select(selectActivitiesForPlans);
    const batch = getMoveWeekBatch(allActivities, startDate, type);

    yield postActivityBatchSaga({ payload: { batch, asyncSaving: true } });
  } catch (e) {
    console.error(e.message);
    yield put(setAlert(generalSaveError));
  }
}

export function* watcherSaga() {
  yield takeEvery(loadGoalsForPlans, loadExtraData);
  yield takeEvery(setCalendarKeys, loadCurricula);
  yield takeEvery(loadTranslationsToNewCurricula, loadTranslationData);
  yield takeEvery(deleteActivity, deleteAllActivity);
  yield takeEvery(saveActivity, saveActivitySaga);
  yield takeEvery(moveWeek, moveWeekSaga);
}
