import { setAlert } from '@store/alerts/alertsState';
import {
  selectCurriculaOptionsForCalendar,
  selectCurriculaValuesAlertForCalendar,
  selectCurriculaValuesForCalendar,
} from '@store/calendar/calendarSelectors';
import { selectCurrentSchoolyear } from '@store/userAndSchool/userAndSchoolSelectors';
import { CurriculaOptionItem, FormResourcePicker } from '@UI/index';
import { isCurriculumValid, requiredValidation } from '@utils/validations';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

const CurriculumSelect = ({ control, name, defaultValue, required = false, onChange }) => {
  if (!name || !control) {
    throw new Error("CurriculumSelect must have a form 'control' and a 'name'");
  }
  const dispatch = useDispatch();
  const currentSchoolyear = useSelector(selectCurrentSchoolyear)?.value;

  const { setValue } = useFormContext();
  const schoolyearSelect = useWatch({
    control,
    name: 'schoolyear',
  });
  const curriculumSelect = useWatch({
    control,
    name,
  });

  const curriculaValues = useSelector((state) =>
    selectCurriculaValuesForCalendar(state, {
      schoolyear: schoolyearSelect ?? currentSchoolyear,
      curriculum: curriculumSelect,
      moveToNextVersion: schoolyearSelect && schoolyearSelect > currentSchoolyear,
    })
  );
  const curriculaOptions = useSelector((state) =>
    selectCurriculaOptionsForCalendar(state, {
      schoolyear: schoolyearSelect ?? currentSchoolyear,
    })
  );

  const curriculaValuesAlert = useSelector((state) =>
    selectCurriculaValuesAlertForCalendar(state, {
      schoolyear: schoolyearSelect ?? currentSchoolyear,
      curriculum: curriculumSelect,
    })
  );

  /**
   * this sets the initial values. this has to happen before the updates in the useEffects below.
   */
  useEffect(() => {
    setValue(name, defaultValue);
    onChange?.(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (curriculumSelect && Array.isArray(curriculaValues)) {
      // if we need to clear the values, we expect an empty array, not null or undefined.
      setValue(name, curriculaValues, { shouldValidate: true });
      onChange?.(curriculaValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculaValues]);

  useEffect(() => {
    if (curriculaValuesAlert) {
      dispatch(setAlert(curriculaValuesAlert));
    }
  }, [curriculaValuesAlert, dispatch]);

  useEffect(() => {
    if (curriculaOptions.length === 0) {
      console.warn('there are no custom curricula for this user. calendar unavailable.');
      dispatch(
        setAlert({
          key: 'no-curricula-error',
          title: 'Geen leerplannen',
          msg: 'Je kan een vorderingsplan enkel baseren op een school-, groeps- of persoonlijk leerplan. Je dient eerst een leerplan aan te maken. Een vorderingsplan kan niet gebaseerd worden op een basis leerplan.',
          type: 'error',
          showClose: true,
          delay: 15000,
        })
      );
    }
  }, [curriculaOptions, dispatch]);

  return (
    <FormResourcePicker
      name={name}
      placeholder="naam leerplan"
      optionValue={(option) => option.key}
      localData={curriculaOptions}
      optionTemplate={(option) => <CurriculaOptionItem option={option} />}
      multiple
      control={control}
      validations={
        required
          ? { required: requiredValidation, valid: isCurriculumValid }
          : { valid: isCurriculumValid }
      }
    />
  );
};

export default CurriculumSelect;
