import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import settings from '@config/settings';

Sentry.init({
  dsn: settings.logging.sentry.url,
  normalizeDepth: 10,
  replaysSessionSampleRate: settings.logging.sentry.replaysSessionSampleRate,
  replaysOnErrorSampleRate: 1.0,
  enabled: settings.logging.sentry.enabled,
  release: settings.release,
  environment: settings.environment === '' ? 'PROD' : settings.environment,
  maxValueLength: 1000,
  tracesSampleRate: settings.logging.sentry.performanceSampleRate,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
    // this allows the user to send issues to sentry
    // Sentry.feedbackIntegration({
    //   // Additional SDK configuration goes in here, for example:
    //   colorScheme: 'system',
    // }),
  ],
});
