import { OldSavedLlinkidApiState, SavedLlinkidApiState } from '@store/llinkidApis/llinkidApiTypes';
import { TableItemsMap } from '../types/genericApiTypes';

function getFileName(schoolhref: string, tableName: string, schoolyear: string) {
  const schoolKey = schoolhref.split('/').pop();
  return `${schoolKey}-${tableName}-${schoolyear}.json`;
}

async function writeDataToFile(filePath: string, data: any) {
  const opfsRoot = await navigator.storage.getDirectory();
  const fileHandle = await opfsRoot.getFileHandle(filePath, { create: true });
  const accessHandle = await fileHandle.createWritable();

  // Convert data to JSON and encode it
  const itemsJson = JSON.stringify(data);
  const textEncoder = new TextEncoder();
  const itemsBuffer = textEncoder.encode(itemsJson);

  // Write the buffer to the file
  await accessHandle.truncate(0); // Truncate the file to empty it
  await accessHandle.write(itemsBuffer);
  await accessHandle.close(); // Close the file handle
}

export async function saveLlinkidDataToFile(
  items: Array<TableItemsMap>,
  tableName: string,
  schoolhref: string,
  schoolyear: string
) {
  const fileName = getFileName(schoolhref, tableName, schoolyear);
  await writeDataToFile(fileName, items);
}

/**
 *
 * @param {*} customCurriculaData
 * @param {string} schoolhref
 */
export async function saveLlinkidStateDataToFile(customCurriculaData, schoolhref: string) {
  try {
    const schoolKey = schoolhref.split('/').pop();
    const fileName = `${schoolKey}-customCurriculaData.json`;
    await writeDataToFile(fileName, customCurriculaData);
  } catch (e) {
    if (process.env.VITEST === undefined) console.warn('Error saving state data to file', e);
  }
}

export async function getLlinkidDataFromFile(
  tableName: string,
  schoolhref: string,
  schoolyear: string
) {
  try {
    if (!navigator.storage) {
      return null;
    }
    const opfsRoot = await navigator.storage.getDirectory();
    const fileHandle = await opfsRoot.getFileHandle(
      getFileName(schoolhref, tableName, schoolyear),
      {
        create: false,
      }
    );

    const file = await fileHandle.getFile();
    const text = await file.text();

    const parsed = JSON.parse(text);
    return parsed;
  } catch (e) {
    if (!(e instanceof DOMException && e.name === 'NotFoundError')) {
      // Log the error message only if it's not a NotFoundError
      console.error(e.message);
    }
    return null;
  }
}

export async function getStateFile(
  schoolhref
): Promise<OldSavedLlinkidApiState | SavedLlinkidApiState | null> {
  try {
    const schoolKey = schoolhref.split('/').pop();
    if (!navigator.storage) {
      return null;
    }
    console.time(`file-get-${schoolKey}-customCurriculaData.json`);
    const opfsRoot = await navigator.storage.getDirectory();
    const fileHandle = await opfsRoot.getFileHandle(`${schoolKey}-customCurriculaData.json`, {
      create: false,
    });

    const file = await fileHandle.getFile();
    const text = await file.text();
    console.timeEnd(`file-get-${schoolKey}-customCurriculaData.json`);
    const parsed = JSON.parse(text);
    return parsed;
  } catch (e) {
    if (!(e instanceof DOMException && e.name === 'NotFoundError')) {
      // Log the error message only if it's not a NotFoundError
      console.error(e.message);
    }
    return null;
  }
}

async function deleteFile(fileName: string) {
  try {
    const opfsRoot = await navigator.storage.getDirectory();
    await opfsRoot.removeEntry(fileName);
  } catch (e) {
    console.error(e.message);
  }
}

export async function deleteStateFile(schoolhref: string) {
  const schoolKey = schoolhref.split('/').pop();
  await deleteFile(`${schoolKey}-customCurriculaData.json`);
}

// @ts-expect-error not used yet
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function deleteAllFiles() {
  // @ts-expect-error remove does exist.
  await (await navigator.storage.getDirectory()).remove({ recursive: true });
}
