import commonUtils from '@kathondvla/sri-client/common-utils';
import {
  getCreatePlanBatch,
  getActivityBatch,
  getAttachmentBatch,
} from '@store/llinkidApis/llinkidApiHelper';
import addWeeks from 'date-fns/addWeeks';
import { generateCalendarWeeksForPlans } from '@utils/dateHelpers';
import isSameDay from 'date-fns/isSameDay';
import addMilliseconds from 'date-fns/addMilliseconds';
import { createWeek } from '../../module/models/week';

function getExtraWeeks(startWeek, amount, type) {
  const { id, startDate, endDate } = startWeek;
  const extraWeeks = [];

  for (let i = 1; i <= amount; i += 1) {
    let nId;
    let nStartDate;
    let nEndDate;

    if (type === 'PREVIOUS') {
      nId = id - i;
      nStartDate = addWeeks(startDate, -i);
      nEndDate = addWeeks(endDate, -i);
    } else if (type === 'NEXT') {
      nId = id + i;
      nStartDate = addWeeks(startDate, i);
      nEndDate = addWeeks(endDate, i);
    }

    const week = createWeek(nId, { start: nStartDate, end: nEndDate });

    extraWeeks.push(week);
  }

  return extraWeeks;
}

function generateCopiedWeeks(copy, originalWeeks) {
  let copiedWeeks = generateCalendarWeeksForPlans([
    {
      issued: {
        startDate: copy.schoolyear.startDate,
        endDate: copy.schoolyear.endDate,
      },
      activities: [],
    },
  ]);

  if (originalWeeks[0].id < 1) {
    const previousWeeks = getExtraWeeks({
      week: copiedWeeks[0],
      amount: (originalWeeks[0].id - copiedWeeks[0].id) * -1,
      type: 'PREVIOUS',
    });
    copiedWeeks = [...previousWeeks.reverse(), ...copiedWeeks];
  }

  if (originalWeeks.length > copiedWeeks.length) {
    const nextWeeks = getExtraWeeks({
      week: copiedWeeks[copiedWeeks.length - 1],
      aomunt: originalWeeks.length - copiedWeeks.length,
      type: 'NEXT',
    });
    copiedWeeks = [...copiedWeeks, ...nextWeeks];
  }

  return copiedWeeks;
}

export function getGoalsToCopy(goals = [], goalTranslationsMap = {}) {
  const goalsWithNextVersion = new Set(goals.map((g) => goalTranslationsMap[g.href] || g.href));

  return [...goalsWithNextVersion].map((href) => {
    return { href };
  });
}

function generateCopyActivity(activity, originalWeeks, copiedWeeks, goalTranslationsMap) {
  const key = commonUtils.generateUUID();
  const startIndex = originalWeeks.findIndex((w) =>
    isSameDay(w.startDate, new Date(activity.period.startDate))
  );
  const endIndex = originalWeeks.findIndex((w) =>
    isSameDay(addMilliseconds(w.endDate, -1), new Date(activity.period.endDate))
  );
  const period = {
    startDate: copiedWeeks[startIndex].startDate,
    endDate: addMilliseconds(copiedWeeks[endIndex].endDate, -1),
  };

  const goals = getGoalsToCopy(activity.goals, goalTranslationsMap);

  return { key, item: { ...activity, goals }, period };
}

export const getCopyCalendarBatch = ({ original, copy, classesMap, goalTranslationsMap }) => {
  const planBatch = getCreatePlanBatch(copy);
  const batch = planBatch;

  const originalWeeks = generateCalendarWeeksForPlans(original.plans);
  const copiedWeeks = generateCopiedWeeks(copy, originalWeeks);
  const attachmentsToUpload = {
    items: [],
    files: [],
  };

  Object.keys(classesMap).forEach((orginalClass) => {
    const originalPlan = original.plans.find((p) => p.class.href.includes(orginalClass));
    const parents = originalPlan.activities.filter((a) => a.parent.href.includes(originalPlan.key));

    classesMap[orginalClass]?.forEach((newClass) => {
      const plan = planBatch.find((p) => p.body.class && p.body.class.href.includes(newClass.key));

      parents.forEach((parent) => {
        const { key, item, period } = generateCopyActivity(
          parent,
          originalWeeks,
          copiedWeeks,
          goalTranslationsMap
        );
        batch.push(getActivityBatch({ key, parentHref: plan.href, activity: item, period }));

        originalPlan.activities
          .filter((a) => a.parent.href.includes(parent.key))
          .forEach((child) => {
            const {
              key: childKey,
              item: childItem,
              period: childPeriod,
            } = generateCopyActivity(child, originalWeeks, copiedWeeks, goalTranslationsMap);

            if (child.$$attachments) {
              child.$$attachments.forEach((att) => {
                attachmentsToUpload.items.push(
                  getAttachmentBatch({ activityKey: childKey, attachment: att.$$expanded })
                );
              });
            }

            batch.push(
              getActivityBatch({
                key: childKey,
                parentHref: `/llinkid/activityplanning/activityplans/activities/${key}`,
                activity: childItem,
                period: childPeriod,
              })
            );
          });
      });
    });
  });

  return [planBatch, attachmentsToUpload];
};

export const getPlanCreatorsAndObservers = (plan, orgsTeacherMap) => {
  const creatorsAndObserversForPlan = {};

  const creators = new Set();
  plan.creators.forEach((creator) => {
    if (orgsTeacherMap[creator.href]) {
      orgsTeacherMap[creator.href].forEach((href) => creators.add(href));
    }
  });
  creatorsAndObserversForPlan.$$creatorsSet = creators; // /we use the set internally
  creatorsAndObserversForPlan.$$creators = [...creators]; // /we use an array externally, because a set doesn't clone with JSON

  const observers = new Set();
  plan.observers?.forEach((creator) => {
    if (orgsTeacherMap[creator.href]) {
      orgsTeacherMap[creator.href].forEach((href) => observers.add(href));
    }
  });
  creatorsAndObserversForPlan.$$observerSet = observers; // /we use the set internally
  creatorsAndObserversForPlan.$$observers = [...observers]; // /we use an array externally, because a set doesn't clone with JSON

  return creatorsAndObserversForPlan;
};

export const filterData = ({ deletedCalendars, name }, list) => {
  let filteredList = [...list];

  if (deletedCalendars) filteredList = filteredList.filter((e) => e.deleted);
  else filteredList = filteredList.filter((e) => !e.deleted);

  if (name) {
    filteredList = filteredList.filter((result) => {
      const regExp = new RegExp(name, 'gi');
      return (
        regExp.test(result.classes) || regExp.test(result.plan) || regExp.test(result.teachers)
      );
    });
  }

  return filteredList;
};

export const getRemoveCalendarBatch = (plans) => {
  const batch = [];

  const timestamp = new Date().toISOString();

  plans.forEach((plan) => {
    batch.push({
      verb: 'PUT',
      href: plan.$$meta.permalink,
      body: { ...plan, softDeleted: timestamp },
    });
  });

  return batch;
};
