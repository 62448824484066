import { generalSaveError } from '@store/alerts/alertConstants';
import { setAlert } from '@store/alerts/alertsState';
import { selectCreatorFromCurriculumNiveau } from '@store/curriculum/curriculumSelectors';
import { selectCustomCurriculaData } from '@store/llinkidApis/llinkidApiSelectors';
import { selectStudyProgrammesFromKeys } from '@store/studyProgrammesApi/studyProgrammeSelectors';
import {
  selectCurrentSchool,
  selectCurrentSchoolyear,
} from '@store/userAndSchool/userAndSchoolSelectors';
import { filterByPermalinks, filterCustomCurriculaWithFilters } from '@utils/filters';
import { getSelectableProgramsForCurriculumListItem } from '@utils/homeHelper';

import { last } from 'lodash-es';
import { put, select, takeLatest } from 'redux-saga/effects';
import { saveLlinkidApiBatch } from '@store/llinkidApis/llinkidApiState';
import { selectTryCreateCustomCurriculaBatch } from '@store/llinkidApis/userActionsSelectors';

import { saveLlinkidApiBatchAndWaitForResponse } from '@utils/sagaUtils';
import { leerplanListActions } from './leerplanListState';

function endDateOrDelete(customCur, currentSchoolyear) {
  /**
   * what needs to happen when?
   * if cc startDate = current schoolyear startdate => delete
   * if cc startDate < current schoolyear startdate: set enddate to end of last schoolyear?
   */
  if (customCur.issued.startDate === currentSchoolyear.isoDates.startDate) {
    return { deleteCur: true };
  }
  if (customCur.issued.startDate < currentSchoolyear.isoDates.startDate) {
    const endDateLastSchoolyear = new Date(currentSchoolyear.endDate);
    endDateLastSchoolyear.setFullYear(endDateLastSchoolyear.getFullYear() - 1);
    return { endDate: endDateLastSchoolyear.toISOString() };
  }
  return undefined;
}

function* deleteCurricula({ payload }) {
  try {
    const { custIds } = payload;
    const batch = [];
    const customCurricula = yield select((state) => state.customCurriculaData.customCurricula);
    const annotations = yield select((state) =>
      Object.values(state.customCurriculaData.annotations).filter((e) =>
        custIds.includes(e.curriculum.href.split('/').pop())
      )
    );

    const currentSchoolyear = yield select((state) => selectCurrentSchoolyear(state));

    custIds.forEach((curId) => {
      const customCur = customCurricula[curId];
      if (customCur) {
        const { deleteCur, endDate } = endDateOrDelete(customCur, currentSchoolyear);
        if (deleteCur === true) {
          batch.push({ verb: 'DELETE', href: customCur.$$meta.permalink });
          annotations
            .filter((e) => e.curriculum.href === customCur.$$meta.permalink)
            .forEach((ann) => batch.push({ verb: 'DELETE', href: ann.$$meta.permalink }));
        } else {
          batch.push({
            verb: 'PUT',
            href: customCur.$$meta.permalink,
            body: { ...customCur, issued: { startDate: customCur.issued.startDate, endDate } },
          });
        }
      }
    });

    yield put(saveLlinkidApiBatch({ batch, applyInstantly: false }));
  } catch (e) {
    console.error(e.message);
    yield put(setAlert(generalSaveError));
  }
}

function* editNonderived({ payload }) {
  try {
    const { item, formValues } = payload;

    const customCurs = yield select(selectCustomCurriculaData);
    const nonderived = filterCustomCurriculaWithFilters(customCurs, [
      filterByPermalinks(item.sources.map((i) => i.href)),
    ]);

    const { name, code } = formValues;

    const batch = nonderived.map((curr) => {
      return {
        verb: 'PUT',
        href: curr.$$meta.permalink,
        body: { ...curr, title: name, identifier: code },
      };
    });

    yield put(saveLlinkidApiBatch({ batch, applyInstantly: false }));
  } catch (e) {
    console.error(e.message);
    yield put(setAlert(generalSaveError));
  }
}

export function* tryCreateCustomCurriculaAndWaitForResponse(
  curriculum,
  creatorHref,
  studyPrograms
) {
  const { custCurs, batch } = yield select((state) =>
    selectTryCreateCustomCurriculaBatch(state, {
      curriculum,
      creatorHref,
      studyPrograms,
    })
  );

  if (batch.length > 0) {
    const batchResult = yield saveLlinkidApiBatchAndWaitForResponse(batch);
    if (batchResult.isFailed) {
      throw new Error(batchResult.response);
    }
  }

  const setid = custCurs[0].customCurriculaGroup
    ? last(custCurs[0].customCurriculaGroup.href.split('/'))
    : undefined;
  const studid = studyPrograms.map((m) => last(m.split('/'))).join(',');
  const custid = custCurs.length === 1 ? custCurs[0].key : undefined;

  return { studid, setid, custid };
}

function* createNonderivedCurricula({ payload }) {
  try {
    const curr = {
      identifiers: payload.code,
      title: payload.name,
      curriculumType: 'CUSTOM',
      selectablePrograms: [], // we don't need selectablePrograms because we send the individual programs. not the groups.
      version: {
        major: 1,
        minor: 0,
        patch: 0,
      },
    };

    const studyPrograms = payload.studyProgram.map((e) => e.value);

    const creator = yield select((state) => selectCreatorFromCurriculumNiveau(state, payload));

    const { studid, setid } = yield tryCreateCustomCurriculaAndWaitForResponse(
      curr,
      creator,
      studyPrograms
    );

    globalThis.router.navigate({
      pathname: '/leerplan/nonderived/doelenlijst',
      search: `studid=${studid}&setid=${setid}`,
    });
  } catch (e) {
    console.error(e.message);
    yield put(setAlert(generalSaveError));
  }
}

function* createCurricula({ payload }) {
  try {
    const curriculum = payload.item;
    const { creator } = payload.formValues;
    let { studyProgram } = payload.formValues;

    const currentSchool = yield select((state) => selectCurrentSchool(state));
    const group = currentSchool.studyProgrammeGroups.find((g) =>
      g.studyProgrammes.map((program) => program.href === studyProgram)
    );

    if (!Array.isArray(studyProgram)) {
      studyProgram = [studyProgram];
    }

    const selectablePrograms = getSelectableProgramsForCurriculumListItem(
      curriculum,
      currentSchool
    );

    const studyPrograms = yield select((state) =>
      selectStudyProgrammesFromKeys(state, selectablePrograms, studyProgram)
    );

    const key = group ? group.key : last(studyProgram.split('/'));

    const curr = {
      curriculumType: 'ADAPTATION',
      sources: curriculum.sources,
      selectablePrograms,
    };

    const { studid, setid, custid } = yield tryCreateCustomCurriculaAndWaitForResponse(
      curr,
      creator,
      studyPrograms
    );

    let search = `custid=${custid}&groupid=${key}&studid=${studid}`;
    if (setid) {
      search += `&setid=${setid}`;
    }
    globalThis.router.navigate({
      pathname: `/leerplan/${curriculum.id}/doelenlijst`,
      search,
    });
  } catch (e) {
    console.error(e.message);
    yield put(setAlert(generalSaveError));
  }
}

export function* watchInitDataSaga() {
  yield takeLatest(leerplanListActions.deleteCurricula, deleteCurricula);
  yield takeLatest(leerplanListActions.editNonderived, editNonderived);
  yield takeLatest(leerplanListActions.createCurricula, createCurricula);
  yield takeLatest(leerplanListActions.createNonderivedCurricula, createNonderivedCurricula);
}
