import * as commonUtils from '@kathondvla/sri-client/common-utils';
import { lruMemoize } from '@reduxjs/toolkit';
import { getPlanCreatorsAndObservers } from '@store/calendarList/calendarListHelper';
import {
  selectActiveCurricula,
  selectEducationalPointers,
  selectNextVersionsForCurricula,
} from '@store/contentApi/contentApiSelectors';
import {
  buildGoalsFromExtractedCurriculumNodes,
  compareGoalDescription,
  getGoalsToLoad,
  sortByIdentifier,
} from '@store/curriculum/curriculumAndAnnotationHelper';
import {
  selectAllGoals,
  selectGoalsFromCurriculum,
  selectIsCurriculumLoaded,
} from '@store/curriculum/curriculumSelectors';
import { createTypedSelector } from '@store/genericHelpers';
import { mapCurriculaToParams } from '@store/leerplanList/leerplanListHelpers';
import {
  groupedHomePageListSelector,
  selectIsCurriculaListLoading,
  selectUngroupedHomePageListIncludingPreviousYearList,
  ungroupedHomePageListSelector,
} from '@store/leerplanList/leerplanListSelectors';
import {
  selectActivitiesData,
  selectActivityPlansData,
  selectIsDataLoadedForTypes,
} from '@store/llinkidApis/llinkidApiSelectors';
import { RootState } from '@store/storeSetup';
import {
  selectActiveClassesInSchoolyear,
  selectAllClassesInSchoolyear,
  selectAllCreatorsActiveInSchoolyear,
  selectIsSchoolyearReadOnly,
  selectOrgsTeacherMapForSchoolyear,
  selectSchoolyearsForSchool,
  selectUser,
} from '@store/userAndSchool/userAndSchoolSelectors';
import { VmClass } from '@store/userAndSchool/userAndSchoolTypes';
import {
  filterByCustomCurriculaGroup,
  filterByKeys,
  filterBySchoolyear,
  filterCustomCurriculaWithFilters,
} from '@utils/filters';
import {
  conditionalLogTime,
  deepFreeze,
  getAllCreatorsWithIcon,
  getClassesOptions,
  getSchoolyears,
  groupCurriculaValuesByGrade,
  groupTeachersValueByLevel,
  isActivityplanInSchoolyear,
  isCustomCurriculaSet,
  RESOURCES,
} from '@utils/utils';
import { getKeyFromHref } from '@utils/getKeyFromHref';
import format from 'date-fns/format';
import { cloneDeep, isEqual, last, omit, uniqBy } from 'lodash-es';
import { shallowEqual } from 'react-redux';
import { CurriculumRoot } from '../../types/contentApiTypes';
import { ApiMeta } from '../../types/genericApiTypes';
import { ApiActivity, ApiAttachment } from '../../types/llinkidApiTypes';
import { BatchRequest } from '../../types/sriTypes';
import { WEEK_TITLE_TYPE } from './calendarConsts';
import {
  fillExpandedClasses,
  fillExpandedCreators,
  filterActivitiesByRange,
  filterValidCalendarCreationCurriculum,
  formatDateOptions,
  getActivitiesForPlan,
  groupActivitiesByParent,
  sortByClassName,
  sortByPeriod,
} from './calendarHelper';
import { isEditActivityModalData } from './calendarTypes';
import {
  createPlans,
  fillGoals,
  generateCalendarForPlans,
  generateEmptyCalendar,
  getColorForClasses,
  getPlanName,
  getPlanSubtitle,
  getSchoolyearForPlan,
  getTeachersName,
  hasher,
  makeActivityDates,
} from './calendarVMHelper';

export const selectDraftSheetVM = createTypedSelector(
  [
    selectActivityPlansData,
    selectActivitiesData,
    selectAllClassesInSchoolyear,
    selectAllCreatorsActiveInSchoolyear,
    (state, { keys, start, end }) => ({ keys, start, end }),
  ],
  (activityPlans, activities, classes, teachers, { keys, start, end }) => {
    if (!keys) return null;
    if (!activityPlans || !activities) return null;

    const plans = activityPlans
      .filter((p) => keys.includes(p.key))
      .map((p) => fillExpandedClasses(p, classes))
      .map((p) => fillExpandedCreators(p, teachers))
      .sort(sortByClassName);

    if (plans.length !== keys.length) {
      console.error('Not all activityplans were found in cache storage');
    }

    const plansWithActivities = plans.map((plan) => {
      const planActivities = getActivitiesForPlan(plan, activities);
      const filterendActivities = filterActivitiesByRange(planActivities, {
        start,
        end,
      });
      const groupedActivities = groupActivitiesByParent(filterendActivities);

      return {
        ...plan,
        activities: groupedActivities,
        calendarRange: `${format(new Date(start), 'dd/MM')} - ${format(new Date(end), 'dd/MM')}`,
      };
    });
    return plansWithActivities;
  }
);

const selectSchoolyearFromPlans = createTypedSelector(
  [(state) => state.customCurriculaData.activityPlans, (state, params) => params.plans],
  (activityPlans, plans) => {
    if (!plans || Object.keys(activityPlans).length === 0) return null;
    const planKeys = plans.map((e) => e.key);
    const plan = activityPlans[planKeys[0]];
    if (!plan) return null;
    const planSchoolyear = getSchoolyears().find((e) =>
      isActivityplanInSchoolyear(plan.issued.startDate, plan.issued.endDate, e)
    );
    return planSchoolyear;
  }
);

export const selectCalendarExistsForCurriculum = (state: RootState, params) => {
  const activityPlans = Object.values(state.customCurriculaData.activityPlans);
  const curOrSetids = new Set();
  activityPlans.forEach((plan) =>
    plan.curricula.forEach((curriculum) => curOrSetids.add(last(curriculum.href.split('/'))))
  );
  return params.custIds.some((e) => curOrSetids.has(e));
};

const selectCustomCurriculaFromPlans = createTypedSelector(
  [
    (state) => state.customCurriculaData.activityPlans,
    (state) => state.customCurriculaData.customCurricula,
    (state, params) => params.plans,
  ],
  (activityPlans, customCurricula, plans) => {
    if (!plans) return [];
    const planKeys = plans.map((e) => e.key);
    const endLogTime = conditionalLogTime('selectCustomCurriculaFromPlans', 2);

    const hset = new Set<string>();
    planKeys.forEach((e) => activityPlans[e]?.curricula.forEach((q) => hset.add(q.href)));

    const custCursPerHref = {};
    if (hset.size !== 0) {
      for (const href of hset) {
        let custCurs = [];
        if (isCustomCurriculaSet(href)) {
          custCurs = filterCustomCurriculaWithFilters(Object.values(customCurricula), [
            filterByCustomCurriculaGroup(getKeyFromHref(href)),
          ]);
        } else {
          custCurs = filterCustomCurriculaWithFilters(Object.values(customCurricula), [
            filterByKeys([getKeyFromHref(href)]),
          ]);
        }

        custCursPerHref[href] = custCurs;
      }
    }
    endLogTime();
    return custCursPerHref;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectActiveCustomCurriculaParamsFromPlans = createTypedSelector(
  [
    (state) => state.customCurriculaData.activityPlans,
    selectCustomCurriculaFromPlans,
    (state, params) =>
      selectSchoolyearFromPlans(state, params)?.key && // when changing school, the plans might not be in the state yet. so wait for that to return a schoolyear.
      selectActiveCurricula(state, {
        schoolyearKey: selectSchoolyearFromPlans(state, params)?.key as string,
      }),
    (state, params) => params.plans,
  ],
  (activityPlans, custCursPerHref, activeCurriculaRoots, plans) => {
    if (!plans || !activeCurriculaRoots) return [];
    const planKeys = plans.map((e) => e.key);
    const endLogTime = conditionalLogTime('selectActiveCustomCurriculaParamsFromPlans', 2);

    const paramList: Array<{
      curriculumKey: string;
      custids: string[];
      schoolyear: string;
    }> = [];

    if (Object.keys(custCursPerHref).length !== 0) {
      // could be 0 if we changed school-context
      const plan = activityPlans[planKeys[0]];
      const planSchoolyear = getSchoolyears().find((e) =>
        isActivityplanInSchoolyear(plan.issued.startDate, plan.issued.endDate, e)
      );

      if (!planSchoolyear) return [];

      for (const href of Object.keys(custCursPerHref)) {
        let custCurs = filterCustomCurriculaWithFilters(custCursPerHref[href], [
          filterBySchoolyear(planSchoolyear),
        ]);

        custCurs = custCurs.filter(
          // filter out the customcurricula that are based on inactive base curricula (basisopties)
          (e) =>
            !e.source ||
            !e.source.href.includes('content') || // derived non-derived
            activeCurriculaRoots.some((z) => z.$$meta.permalink === e.source.href)
        );

        if (custCurs[0]) {
          const curriculumKey =
            custCurs[0].source && custCurs[0].source.href.startsWith('/content')
              ? getKeyFromHref(custCurs[0].source.href)
              : 'nonderived';

          paramList.push({
            curriculumKey,
            custids: custCurs.map((e) => e.key),
            schoolyear: planSchoolyear.key,
          });
        }
      }
    }
    endLogTime();
    return paramList;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

/**
 * returns all the goals that exist in a the curricula that are used in the plans.
 */
const selectGoalsFromCurriculumOfPlans = createTypedSelector(
  [
    (state, params) =>
      selectActiveCustomCurriculaParamsFromPlans(state, params).map((par) =>
        // @ts-expect-error it's not inferring the type correctly from selectGoalsFromCurriculum
        selectGoalsFromCurriculum(state, par)
      ),
  ],
  (goalsPerCurricula) => {
    return [...goalsPerCurricula.filter((e) => e).flat()];
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

const selectIncludedGoalHrefsInPlans = createTypedSelector(
  [
    (state) => state.customCurriculaData.activityPlans,
    (state) => state.customCurriculaData.activities,
    (state, params) => params.plans,
  ],
  (activityPlans, activities, plans) => {
    if (!plans) return [];
    const endLogTime = conditionalLogTime('selectIncludedGoalHrefsInPlans', 2);
    const newPlans = plans
      .map((e) => e.key)
      .map((e) => activityPlans[e])
      .filter((e) => e); // could be empty if we changed school-context

    const goals = new Set();

    newPlans.forEach((plan) => {
      getActivitiesForPlan(plan, Object.values(activities)).forEach((act) => {
        if (act.goals) {
          act.goals.forEach((goal) => {
            goals.add(goal.href);
          });
        }
      });
    });
    endLogTime();
    return [...goals];
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectExtraGoalsToLoadFromPlans = createTypedSelector(
  [
    (state) => state.curriculum.preview,
    selectIncludedGoalHrefsInPlans,
    selectAllGoals,
    (state) => state.contentApiData.goalsToLoad,
    (state, params) => params.plans,
  ],
  (preview, goalsInPlans, allGoals, goalsToLoadStatuses) => {
    const goalsToLoad = new Set(goalsInPlans);
    // USING SAME LOGIC AS selectExtraGoalsToLoad
    return getGoalsToLoad(goalsToLoad, allGoals, goalsToLoadStatuses, preview);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectDisappearedGoalsInPlans = createTypedSelector(
  [
    selectIncludedGoalHrefsInPlans,
    selectGoalsFromCurriculumOfPlans,
    (state, params) => params.plans,
  ],
  (goalHrefsInPlan, goalsInCurriculaOfPlans) => {
    const fromCur = new Set(goalsInCurriculaOfPlans.map((e) => e.href));
    return goalHrefsInPlan.filter((e) => !fromCur.has(e));
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectGoalsFromPlans = createTypedSelector(
  [
    selectIncludedGoalHrefsInPlans,
    selectAllGoals,
    selectEducationalPointers,
    (state) => state.studyProgrammeApiData.educationalActivityTypes,
    selectDisappearedGoalsInPlans,
    (state, params) => params.plans,
  ],
  (goalsInPlans, allGoals, edPointers, educationalActivityTypes, disappearedGoals) => {
    // something like selectGoalsFromCurriculum. built goals.
    const goals = goalsInPlans.map((href) => allGoals[getKeyFromHref(href)]).filter((e) => e);

    if (!goals.length) return goals;

    const builtGoals = buildGoalsFromExtractedCurriculumNodes(
      goals,
      edPointers,
      educationalActivityTypes
    );

    const disappearedSet = new Set(disappearedGoals);

    const builtGoalsWithDisappeared = builtGoals.map((e) => ({
      ...e,
      disappeared: disappearedSet.has(e.href),
    }));

    return builtGoalsWithDisappeared;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectArePlanGoalsLoaded = createTypedSelector(
  [
    selectIncludedGoalHrefsInPlans,
    selectAllGoals,
    (state) => state.contentApiData.goalsToLoad,
    (state, params) => params.plans,
  ],
  (goalsInPlans, allGoals, goalsToLoadStatuses) => {
    const allGoalsFound = goalsInPlans.every(
      (href) =>
        allGoals[getKeyFromHref(href)] || goalsToLoadStatuses[getKeyFromHref(href)]?.isFailed
    );

    return allGoalsFound;
  }
);

/**
 * returns all the goal hrefs that exist in a the selected curricula
 */
const selectGoalsFromSelectedCurriculum = createTypedSelector(
  [
    (state, params) =>
      // @ts-expect-error it's not inferring the type correctly from selectGoalsFromCurriculum
      params.selectedCurriculum?.map((curParams) => selectGoalsFromCurriculum(state, curParams)),
  ],
  (goalsPerCurricula) => {
    const map = {};
    goalsPerCurricula
      ?.filter((e) => e)
      .flat()
      .forEach((goal) => {
        map[goal.key] = goal;
      });
    return map;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectSelectedCurriculumLoaded = createTypedSelector(
  [
    (state, params) =>
      // @ts-expect-error it's not inferring the type correctly
      params.selectedCurriculum?.map((curParam) => selectIsCurriculumLoaded(state, curParam)),
  ],
  (selectedCurriculumLoadedStates) => {
    return selectedCurriculumLoadedStates?.every((e) => e);
  },
  {
    memoizeOptions: {},
  }
);

/**
 * returns all the goals that are not found in the selected curricula, as well as the goalsCorrelations map
 */
export const selectMissingGoals = createTypedSelector(
  [
    (state) => state.contentApiData.goalsCorrelations,
    selectGoalsFromSelectedCurriculum,
    (state, params) => params.usedGoals,
    // (state, params) => params.selectedCurriculum,
    // (state, params) => params.schoolyear,
    // (state, params) => params.originalCurriculum,
  ],
  (
    goalsCorrelations,
    goalsFromSelectedCurr,
    usedGoals
    // selectedCurriculum,
    // schoolyear,
    // originalCurriculum
  ) => {
    const notFoundGoals: Array<{ href: string }> = [];
    const goalTranslationsMap: Record<string, string> = {};
    // the goalsTranslationsMap was a map with all usedGoals having a translation.
    // the spec changed. Now, if we can't find a goal's new version, we leave the old goal in the calendar.
    // this means the goalsTranslationsMap now only contains goals that have a new version of that goal.

    usedGoals?.forEach((goal) => {
      if (!goalsFromSelectedCurr[goal.key]) {
        notFoundGoals.push(goal);
      }
    });

    const missingGoals: Array<{ href: string }> = [];

    for (const goal of notFoundGoals) {
      let goalHref: string | null = goal.href;
      let replacingGoal;
      // the goalsCorrelations are simply per version. v1 => v2 and v2 => v3
      // this means, if we need to translate a goal from v1 to v3 in the calendar, we need to go to v2 first and then continue down to v3
      // the loop below follows the next versions until the goal is found or it's the end of the road, version wise.
      do {
        const goalCorrelation = goalsCorrelations[goalHref];
        if (!goalCorrelation || goalCorrelation.length === 0 || goalCorrelation.length > 1) {
          // if there isn't a 1 to 1 match, then remove the goal.
          goalHref = null;
        } else {
          [goalHref] = goalCorrelation;
          const missingGoalKey = getKeyFromHref(goalHref);
          replacingGoal = goalsFromSelectedCurr[missingGoalKey];
        }
      } while (goalHref && !replacingGoal);

      if (!replacingGoal) {
        // console.warn('no replacement found for ', goal.href);
        missingGoals.push(goal);
      } else {
        const isSameText = compareGoalDescription(replacingGoal, goal);
        if (!isSameText) {
          missingGoals.push(goal);
        } else {
          goalTranslationsMap[goal.href] = replacingGoal.href;
        }
      }
    }

    const sortedGoals: Array<{ href: string }> = sortByIdentifier(
      missingGoals.map((z) => {
        return omit(z, ['disappeared']);
      })
    );

    return { missingGoals: sortedGoals, goalTranslationsMap };
  }
);

export const selectClassesOptionsForCalendar = createTypedSelector(
  [
    (state, params) => selectActiveClassesInSchoolyear(state, params),
    (state, params) => params?.schoolyear,
    (state, params) => params?.classes,
  ],
  (activeClasses, schoolyear, classes) => {
    const endLogTime = conditionalLogTime('selectClassesOptionsForCalendar', 2);

    let selectedClasses: Array<{ key: string }> = [];
    if (classes) {
      Object.keys(classes).forEach((key) => {
        if (classes[key]) {
          selectedClasses = [...selectedClasses, ...classes[key]];
        }
      });
    }
    const options = getClassesOptions(
      activeClasses,
      selectedClasses.map((e) => e.key)
    );
    endLogTime();
    return options;
  }
);

export const selectClassesValuesForCalendar = createTypedSelector(
  [
    (state, params) => selectActiveClassesInSchoolyear(state, params),
    (state, params) => params?.schoolyear,
    (state, params) => params?.classes,
  ],
  (activeClasses, schoolyear, classes) => {
    if (!classes) return classes;
    const endLogTime = conditionalLogTime('selectClassesValuesForCalendar', 2);
    const options = getClassesOptions(activeClasses);
    const optionsMap = new Map(options.map((o) => [o.key, o]));

    let newClasses = { ...classes };

    Object.keys(classes).forEach((key) => {
      let newSelectedClasses: Array<VmClass> | null = null;
      if (classes[key]) {
        newSelectedClasses = [];
        classes[key].forEach((selectedClass) => {
          if (!optionsMap.has(selectedClass.key)) {
            const classWithSameName = options.find(
              (c) => c.$$displayName.toLowerCase() === selectedClass.$$displayName.toLowerCase()
            );
            if (classWithSameName) {
              newSelectedClasses?.push(classWithSameName);
            }
          } else {
            newSelectedClasses?.push(selectedClass);
          }
        });
      }
      newClasses = { ...newClasses, [key]: newSelectedClasses };
    });
    endLogTime();
    return newClasses;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectCurriculaOptionsForCalendar = createTypedSelector(
  [(state, params) => groupedHomePageListSelector(state, params?.schoolyear), selectUser],
  (curriculaList, user) => {
    const endLogTime = conditionalLogTime('selectCurriculaOptionsForCalendar', 2);
    const curricula = filterValidCalendarCreationCurriculum(curriculaList, user);
    endLogTime();
    return curricula;
  }
);

export const selectCurriculaValuesForCalendar = createTypedSelector(
  [
    selectIsCurriculaListLoading,
    (state, params) =>
      selectUngroupedHomePageListIncludingPreviousYearList(state, params.schoolyear),
    (state) => selectNextVersionsForCurricula(state),
    (state, params) => params.schoolyear,
    (state, params) => params.curriculum,
    (state, params) => params.moveToNextVersion,
  ],
  (
    isLoading,
    ungroupedcurriculaList,
    nextVersionsMap,
    schoolyear,
    curricula,
    moveToNextVersion
  ) => {
    if (isLoading) return undefined;
    if (!schoolyear && !curricula) return null;
    // const endLogTime = conditionalLogTime('selectCurriculaValuesForCalendar', 2);

    const selectedCurricula: Array<
      CurriculumRoot & {
        valid?: boolean;
      }
    > = [];
    curricula?.forEach((curr) => {
      const { key, id, creator } = curr;
      const found = ungroupedcurriculaList.find((c) => c.key === key);

      let foundNewVersion = false;
      if (moveToNextVersion) {
        nextVersionsMap.get(id)?.forEach((nextVersionId) => {
          const nextVersionCurriculum = ungroupedcurriculaList.find(
            (c) => c.id === nextVersionId && c.creator === creator
          );

          if (nextVersionCurriculum) {
            selectedCurricula.push(nextVersionCurriculum);
            foundNewVersion = true;
          }
        });
      }

      if (found && !foundNewVersion) selectedCurricula.push(found);
    });

    if (!selectedCurricula.length) return selectedCurricula;

    const { values } = groupCurriculaValuesByGrade(selectedCurricula);
    // endLogTime();
    return values;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  }
);

export const selectCurriculaValuesAlertForCalendar = createTypedSelector(
  [selectIsCurriculaListLoading, (state, params) => params.curriculum],
  (isLoading, curricula) => {
    if (isLoading) return undefined;
    // console.log('selectCurriculaValuesAlertForCalendar', curricula);
    if (!curricula || !curricula.length) return null;

    const { alert } = groupCurriculaValuesByGrade(curricula);
    return alert;
  }
);

export const selectTeachersFromPlan = createTypedSelector(
  [selectAllCreatorsActiveInSchoolyear, selectUser, (state, params) => params.plan],
  (allCreators, me, plan) => {
    // const endLogTime = conditionalLogTime('selectTeachersFromPlan', 2);
    const allCreatorsWithIcon = getAllCreatorsWithIcon(allCreators);
    const teachers = plan
      ? allCreatorsWithIcon.filter((c) =>
          plan.plans[0].creators.map((p) => p.href).includes(c.$$meta.permalink)
        )
      : [allCreatorsWithIcon.find((e) => e.href === me?.$$meta.permalink)];

    const observers = plan
      ? allCreatorsWithIcon.filter((c) =>
          plan.plans[0].observers?.map((p) => p.href).includes(c.$$meta.permalink)
        )
      : [];
    // endLogTime();
    return { teachers, observers };
  }
);

export const selectTeacherValuesForCalendar = createTypedSelector(
  [
    (state, params) => selectAllCreatorsActiveInSchoolyear(state, params),
    (state, params) => params.teachers,
  ],
  (allCreators, teachers) => {
    const allCreatorsWithIcon = getAllCreatorsWithIcon(allCreators);
    const teachersArray = teachers || [];
    const endLogTime = conditionalLogTime('selectTeacherValuesForCalendar', 2);

    const { values, regrouped, alert } = groupTeachersValueByLevel(teachersArray);
    const existingTeachers = values.filter((e) => allCreatorsWithIcon.find((z) => z.key === e.key));

    // console.log({ values: existingTeachers, regrouped, alert, options });
    endLogTime();
    return { values: existingTeachers, regrouped, alert, options: allCreatorsWithIcon };
  },
  {
    memoize: lruMemoize,
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 3,
    },
    argsMemoize: lruMemoize,
    argsMemoizeOptions: {
      equalityCheck: isEqual,
      maxSize: 3,
    },
  }
);

/* --------------------------------------------- */
/* --------------------------------------------- */
/* --------------------------------------------- */

export const selectCurrentActivityPlans = createTypedSelector(
  [(state) => state.calendar.calendarKeys, (state) => state.customCurriculaData.activityPlans],
  (calendarKeys, allPlans) => {
    return calendarKeys.map((key) => allPlans[key]).filter((e) => e);
  }
);

export const selectIsActivityPlanReadOnly = createTypedSelector(
  [
    (state) => selectCurrentActivityPlans(state)?.[0],
    selectOrgsTeacherMapForSchoolyear,
    selectIsSchoolyearReadOnly,
    selectUser,
  ],
  (currentActivityPlan, orgsTeacherMap, isSchoolyearReadOnly, user) => {
    if (isSchoolyearReadOnly || currentActivityPlan.softDeleted || !user) return true;

    const planCreatorsAndObservers = getPlanCreatorsAndObservers(
      currentActivityPlan,
      orgsTeacherMap
    );

    const userIsCreator = planCreatorsAndObservers?.$$creatorsSet?.has(user.$$meta.permalink);

    return !userIsCreator;
  }
);

const selectClassColorsForPlan = createTypedSelector(
  [selectCurrentActivityPlans, selectAllClassesInSchoolyear],
  (plans, allClasses) => {
    const uncoloredClasses = plans
      .map((p) => fillExpandedClasses(p, allClasses))
      .sort(sortByClassName)
      .map((e) => e.class);

    const classesWithColor = getColorForClasses(uncoloredClasses);
    return classesWithColor;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectActivitiesForPlans = createTypedSelector(
  [selectCurrentActivityPlans, (state) => state.customCurriculaData.activities],
  (plans, allActivities) => {
    let activities: any[] = [];
    plans.forEach((plan) => {
      activities = [...activities, ...getActivitiesForPlan(plan, Object.values(allActivities))];
    });
    activities = makeActivityDates(activities);
    return activities;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectActivitiesForPlan = (state, plan) => {
  return getActivitiesForPlan(plan, Object.values(state.customCurriculaData.activities));
};

const selectPlansVM = createTypedSelector(
  [
    selectCurrentActivityPlans,
    selectClassColorsForPlan,
    selectActivitiesForPlans,
    selectAllClassesInSchoolyear,
  ],
  (plans, classColors, activitiesForPlans, allClasses) => {
    const incomingplans = plans.map((plan) => ({
      ...plan,
      activities: getActivitiesForPlan(plan, activitiesForPlans),
    }));

    return deepFreeze(createPlans(incomingplans, allClasses, classColors));
  }
);

export const selectCalendarCurricula = createTypedSelector(
  [selectPlansVM, ungroupedHomePageListSelector],
  (plans, allCurrs) => {
    const [mainPlan] = plans;
    const curricula = mainPlan.curricula
      .map((cur) => {
        // @ts-expect-error the type from ungroupedHomePageListSelector doesn't seem correct yet
        return allCurrs.find((z) => (z.customCurriculum ?? z.customCurriculaGroup) === cur.href);
      })
      .filter((z) => z);
    return curricula;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

const selectCalendarCurriculaHeaderInfo = createTypedSelector(
  [
    selectPlansVM,
    (state) => selectCustomCurriculaFromPlans(state, { plans: selectCurrentActivityPlans(state) }),
    selectUngroupedHomePageListIncludingPreviousYearList,
  ],
  (plans, custCursPerHref, curriculaListItems) => {
    const [mainPlan] = plans;
    const curricula = mainPlan.curricula
      .map((cur) => {
        const listItem = curriculaListItems.find(
          // @ts-expect-error the type from ungroupedHomePageListSelector doesn't seem correct yet
          (z) => (z.customCurriculum ?? z.customCurriculaGroup) === cur.href
        );
        if (!listItem) {
          return {
            name: 'Onbekend leerplan',
            valid: false,
            hoverText:
              'Het leerplan waarop dit vorderingsplan is gebaseerd kan niet gevonden worden. Mogelijk is het verwijderd.',
          };
        }
        return {
          // @ts-expect-error the type from ungroupedHomePageListSelector doesn't seem correct yet
          name: listItem.name + (listItem.versionNumber ? ` (${listItem.versionNumber})` : ''),
          valid: listItem.valid,
          hoverText: listItem.valid
            ? undefined
            : 'Dit leerplan is niet geldig is in de huidige context.',
          // @ts-expect-error the type from ungroupedHomePageListSelector doesn't seem correct yet
          src: listItem.src,
          curriculum: listItem,
        };
      })
      .filter((z) => z);
    return curricula;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectCalendarHeaderViewModel = createTypedSelector(
  [
    selectPlansVM,
    selectAllCreatorsActiveInSchoolyear,
    selectCalendarCurricula,
    selectCalendarCurriculaHeaderInfo,
    selectSchoolyearsForSchool,
    (state) => state.calendar.unselectedClasses,
  ],
  (
    plans,
    allTeachers,
    calendarCurricula,
    calendarCurriculaHeaderInfo,
    allSchoolyears,
    unselectedClasses
  ) => {
    const [mainPlan] = plans;

    return {
      keys: plans.map((e) => e.key),
      classes: plans.map((e) => ({
        ...e.class,
        selected: !unselectedClasses.includes(e.class.href),
      })),
      title: getPlanName(mainPlan, calendarCurricula),
      subtitle: getPlanSubtitle(mainPlan, calendarCurricula),
      curricula: calendarCurriculaHeaderInfo,
      schoolyear: getSchoolyearForPlan(mainPlan, allSchoolyears).shortName,
      teachers: getTeachersName(mainPlan, allTeachers),
    };
  }
);

// const selectSchoolyearForPlan = createTypedSelector(
//   [selectCurrentActivityPlans, selectSchoolyearsForSchool],
//   (plans, schoolyears): Schoolyear => {
//     const plan = plans[0];
//     return getSchoolyearForPlan(plan, schoolyears);
//   }
// );

export const selectAreActivitiesLoadedForSchoolyear = (state: RootState) =>
  selectIsDataLoadedForTypes(state, [RESOURCES.ACTIVITIES]);

export const selectCalendarViewModel = createTypedSelector(
  [
    selectPlansVM,
    (state) => selectGoalsFromPlans(state, { plans: selectCurrentActivityPlans(state) }),
    (state) => state.calendar.unselectedClasses,
    (state) => state.calendar.merge,
  ],
  (plans, planGoals, unselectedClasses, merge) => {
    const endLogTime = conditionalLogTime('selectCalendarViewModel', 2);
    const selectedClassesPlans = cloneDeep(
      plans.filter((p) => !unselectedClasses.includes(p.class.href))
    );

    let weeks;
    let occurencesPerGoal: Map<string, number> | undefined;
    if (selectedClassesPlans.length === 0) {
      weeks = generateEmptyCalendar(plans[0]);
    } else {
      ({ weeks, occurencesPerGoal } = generateCalendarForPlans(selectedClassesPlans, merge));
    }

    const planGoalsWithCount = planGoals.map((e) => ({
      ...e,
      $$count: occurencesPerGoal?.get(e.href) ?? 0,
    }));

    weeks.forEach((week) => {
      week.activityPlans.forEach((activityPlan) => {
        activityPlan.activityGroups.forEach((activityGroup) => {
          activityGroup.goals = fillGoals(activityGroup.goals, planGoalsWithCount);
        });
      });
    });

    const vm = {
      weeks,
      occurencesPerGoal,
      settings: {
        weekTitle: WEEK_TITLE_TYPE.FULL,
        merged: merge,
      },
    };
    // console.log('CALENDAR VM', vm);
    endLogTime();
    return vm;
  }
);

export const selectCalendarEditModalViewModel = createTypedSelector(
  [
    selectPlansVM,
    (state) => selectGoalsFromPlans(state, { plans: selectCurrentActivityPlans(state) }),
    (state) => selectArePlanGoalsLoaded(state, { plans: selectCurrentActivityPlans(state) }),
    (state) => selectCalendarCurricula(state),
    // (state) => state.customCurriculaData.activities,
    (state) => state.calendar.unselectedClasses,
    selectCalendarViewModel,
    (state) =>
      selectGoalsFromCurriculumOfPlans(state, { plans: selectCurrentActivityPlans(state) }),
    (state) => state.calendar.editActivityModal.data,
  ],
  (
    plans,
    planGoals,
    arePlanGoalsLoaded,
    calendarCurricula,
    // activities,
    unselectedClasses,
    calendarVM,
    curriculaGoals,
    modalData
  ) => {
    if (!modalData) return null;

    const activities = plans.reduce(
      (accumulator, plan) => [...accumulator, ...plan.activities],
      []
    );

    const parents = isEditActivityModalData(modalData)
      ? modalData.parentHrefs.map((href) => activities.find((z) => z.key === getKeyFromHref(href)))
      : [];
    const parentPlanHrefs = parents?.map((e) => e.parent.href);
    const relevantPlans = parentPlanHrefs?.length
      ? plans.filter((e) => parentPlanHrefs.includes(e.$$meta.permalink))
      : plans; // when we edit an existing activity, we have parenPlanHrefs, so we filter the relevant plans. when creating a new activity, all plans are relevant
    const classes = relevantPlans.map((e) => ({
      ...e.class,
      selected:
        !unselectedClasses.includes(e.class.href) &&
        modalData.createForClasses.includes(e.class.href),
    }));
    const startDates = calendarVM.weeks.map((w) => formatDateOptions(w.startDate));

    let parent = {
      title: '',
      goals: [],
    } as unknown as ApiActivity;

    let children = [
      {
        key: commonUtils.generateUUID(),
        description: '',
        goals: [],
        $$attachments: [],
      },
    ] as unknown as Array<ApiActivity>;
    // we have an array of parentHrefs. however, it is guaranteed that all parents are exactly the same, they also have exactly the same children.
    // this allows us to build the rest of the VM simply by selecting one parent and its children.
    if (isEditActivityModalData(modalData)) {
      [parent] = parents;
      children = activities
        .filter((e) => e.parent.href === parent.$$meta.permalink)
        .map((e) => ({ ...e, hash: hasher(e) }))
        .sort(sortByPeriod);
    } else {
      parent.period = {
        startDate: modalData.week.startDate,
        endDate: modalData.week.endDate,
      };

      children[0].period = {
        startDate: modalData.week.startDate,
        endDate: modalData.week.endDate,
      };
    }

    const curricula = mapCurriculaToParams(calendarCurricula.filter((cur) => cur.valid));

    const { occurencesPerGoal } = calendarVM;
    // @ts-expect-error for later...
    const baseOccurencesPerGoal = new Map<string, number>([...occurencesPerGoal]);
    // we remove the current selected goals from the count, so that we can hand over a baseline count of occurences in other activities.
    parent.goals?.forEach((e) =>
      // @ts-expect-error for later...
      baseOccurencesPerGoal.set(e.href, baseOccurencesPerGoal.get(e.href) - 1)
    );
    children.forEach((child) =>
      child.goals?.forEach((e) =>
        // @ts-expect-error for later...
        baseOccurencesPerGoal.set(e.href, baseOccurencesPerGoal.get(e.href) - 1)
      )
    );
    curriculaGoals.forEach((g) => {
      if (!baseOccurencesPerGoal.get(g.href)) {
        baseOccurencesPerGoal.set(g.href, 0); // set the defaults
      }
    });

    const includedCurriculaGoals = uniqBy(
      curriculaGoals.filter((e) => !e.excluded),
      'key'
    );

    const vm = {
      classes,
      startDates, // old "weeks" in the resolve.
      baseOccurencesPerGoal,
      curriculaGoals: includedCurriculaGoals,
      invalidGoals: planGoals.filter((e) => !e.valid),
      curricula,
      scrollTo: 'key' in modalData ? modalData.key : null,
      model: {
        title: parent.title,
        parentHrefs: isEditActivityModalData(modalData) ? modalData.parentHrefs : null,
        goals: parent.goals.map((e) => planGoals.find((z) => z.href === e.href)).filter(Boolean),
        period: { startDate: parent.period.startDate, endDate: parent.period.endDate },
        weeks: children.map((z) => {
          return {
            key: z.key, // only used for react component key.
            // @ts-expect-error for later...
            hash: z.hash,
            description: z.description,
            period: { startDate: z.period.startDate, endDate: z.period.endDate },
            goals: z.goals.map((e) => planGoals.find((q) => q.href === e.href)).filter(Boolean),
            attachments: z.$$attachments,
          };
        }),
      },
      missingGoals: [],
      arePlanGoalsLoaded,
    };
    // console.log('calendar edit modal VM', vm);
    return vm;
  }
);

export const selectBatchFromEditModal = (state: RootState, model) => {
  const { parentHrefs, classHrefs, goals, period, title, weeks } = model;
  const batch: BatchRequest = [];
  const parentsHrefsSet = new Set(parentHrefs);
  const attachmentsToUpload: {
    items: Array<any>;
    files: Array<File>;
  } = {
    items: [],
    files: [],
  };

  const attachmentsToDelete: Array<ApiAttachment> = [];
  const plansVM = selectPlansVM(state);
  const planActivities = plansVM.reduce(
    (accumulator, plan) => [...accumulator, ...plan.activities],
    []
  );

  const activities = Object.values(state.customCurriculaData.activities);
  const parents = parentHrefs
    ? activities.filter((act) => parentsHrefsSet.has(act.$$meta.permalink))
    : classHrefs.map((href) => {
        const key = commonUtils.generateUUID();
        return {
          key,
          class: { href },
          parent: { href: plansVM.find((e) => e.class.href === href).$$meta.permalink },
          $$meta: { permalink: `/llinkid/activityplanning/activityplans/activities/${key}` },
        };
      });
  const existingChildrenWithHash = planActivities
    .filter((act) => parentsHrefsSet.has(act.parent.href))
    .map((e) => ({ ...e, hashString: hasher(e) }));

  console.log({ parents, existingChildrenWithHash });

  /**
   * what is this?
   * There was a bug in llinkid, where the activities from the API are refreshed/updated whilst the modal is open
   * in this case, the hash, which we rely on to translate the weeks back to activities, would no longer match
   * this resulted in the activities being deleted, and no new activities being PUT.
   *
   * So, in weeksWithUpdatedHashes we check for every week (that has a hash) if we can find the existing child
   * if we do, then we check if the freshly calculated hash still matches with the hash of the week.
   * if not, we log an error and update it.
   *
   * the whole idea of using a hash rather than a key comes from the angular days, and it makes sense for when we are editing activities for multiple classes.
   * in that case we'd have an array of keys for a single week, but all these activities would have the same hash.
   *  */
  const weeksWithUpdatedHashes = weeks.map((week) => {
    if (week.hash) {
      const existingChild = existingChildrenWithHash.find((z) => z.key === week.key);
      if (!existingChild) return week;

      const { hashString } = existingChild;
      if (hashString === week.hash) return week;

      console.error('hashes are not the same', hashString, week.hash, existingChild, week);
      return {
        ...week,
        // hash: hashString, //comment this out, since it's too dangerous for a HF.
      };
    }
    return week;
  });

  parents.forEach((parent) => {
    const activity = {
      ...parent,
      period,
      goals: goals.map((g) => ({ href: g.href })),
      title,
    };

    batch.push({ verb: 'PUT', body: activity, href: activity.$$meta.permalink });
  });

  weeksWithUpdatedHashes.forEach((week) => {
    let children: Array<ApiActivity> = [];

    // find/create the children
    if (week.hash) {
      // existing one got modified
      children = existingChildrenWithHash
        .filter((e) => e.hashString === week.hash)
        .map((e) => ({ ...state.customCurriculaData.activities[e.key] }));
    } else {
      // new week.
      parents.forEach((parent) => {
        const key = commonUtils.generateUUID();
        children.push({
          key,
          title: null, // the title null is important for the hasher. the hash should remain the same when we get the items (re)fresh(ed) from the API
          parent: { href: parent.$$meta.permalink },
          $$meta: {
            permalink: `/llinkid/activityplanning/activityplans/activities/${key}`,
          } as ApiMeta,
          $$attachments: [],
        } as unknown as ApiActivity); // i'm not sure WHY we make half an activity here, and then add the dates later, but no time now.
      });
    }

    if (week.attachments) {
      const weekAttToUpload = week.attachments.filter((att) => att.$$expanded?.uploaded === false);
      weekAttToUpload.forEach((att) => {
        const { file, href, name, description } = att.$$expanded;
        if (file) attachmentsToUpload.files.push(file);

        children.forEach((child) => {
          let attHref = href;

          if (file) {
            attHref = `${child.$$meta.permalink}/attachments/${name}`;
          }

          attachmentsToUpload.items.push({
            file: name,
            attachment: {
              key: commonUtils.generateUUID(),
              href: attHref,
              description,
            },
            resource: {
              href: child.$$meta.permalink,
            },
          });
        });
      });

      const weekAttToDelete = week.attachments.filter((att) => att.deleted === true);
      weekAttToDelete.forEach((att) => {
        // if we have a file delete, we need to find all original attachments by name
        // if it is a hyperlink attachment, name will be null, and we have to find them by href (which is the link)

        const searchTerm = att.$$expanded.name || att.$$expanded.href;
        children.forEach((child) => {
          const toDelAtt = child.$$attachments.find(
            (z) => (z.$$expanded.name || z.$$expanded.href) === searchTerm
          );
          if (toDelAtt) attachmentsToDelete.push(toDelAtt);
        });
      });
    }

    const childrenGoals = week.goals.map((goal) => ({ href: goal.$$meta.permalink }));

    children.forEach((child) => {
      child.period = { startDate: week.period.startDate, endDate: week.period.endDate };
      child.description = week.description;
      child.goals = childrenGoals;
      child.$$attachments =
        child.$$attachments?.filter(
          (z) => !attachmentsToDelete.find((q) => q === z) // remove deleted att.
        ) || [];

      batch.push({ verb: 'PUT', body: child, href: child.$$meta.permalink });
    });
  });

  existingChildrenWithHash.forEach((oldChild) => {
    if (!weeksWithUpdatedHashes.find((e) => e.hash === oldChild.hashString)) {
      // we can't find this hashstring in the weeks anymore. it must be deleted.
      batch.push({ verb: 'DELETE', href: oldChild.$$meta.permalink });
    }
  });

  attachmentsToDelete.forEach((oldAtt) => {
    batch.push({ verb: 'DELETE', href: oldAtt.$$expanded.$$meta.permalink });
  });

  console.log({ batch });
  return [batch, attachmentsToUpload];
};

export const selectActivitiesForSelection = createTypedSelector(
  [
    (state) => state.customCurriculaData.activities,
    (state) => selectGoalsFromPlans(state, { plans: selectCurrentActivityPlans(state) }),
    (state, params) => params.childrenKeys,
  ],
  (activities, goalsFromPlans, childrenKeys) => {
    const allActivities = Object.values(activities);

    const selection = childrenKeys.map((href) => {
      const child = allActivities.find((act) => act.$$meta.permalink === href);
      const childGoals = child?.goals?.map((g) => {
        const goal = goalsFromPlans.find((z) => z.href === g.href);
        return {
          ...g,
          identifier: goal.identifier,
          description: goal.description,
          foundational: goal.foundational,
        };
      });

      const parent = allActivities.find((act) => act.$$meta.permalink === child?.parent.href);
      const parentGoals = parent?.goals?.map((g) => {
        const goal = goalsFromPlans.find((z) => z.href === g.href);
        return {
          ...g,
          identifier: goal.identifier,
          description: goal.description,
          foundational: goal.foundational,
        };
      });

      return {
        parent: {
          href: parent?.$$meta.permalink,
          $$expanded: { ...parent, goals: parentGoals },
        },
        child: {
          href: child?.$$meta.permalink,
          $$expanded: { ...child, goals: childGoals },
        },
      };
    });

    return selection;
  }
);

export const selectIsActivityEditModalOpen = (state) =>
  state.calendar.editActivityModal.status !== 'CLOSED';
